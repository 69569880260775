import React from 'react';
import { CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { formatDollar, getColorBasedOnValue, extractNumericValue } from '../utilityFunctions';

// Styles for the component
const useStyles = makeStyles((theme) => ({
    cardTitle: {
      fontSize: '0.85rem',
      color: '#003865',
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    cardContent: {
      fontSize: '0.90rem',
      textAlign: 'center',
    },
    gridItem: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100px',
      gap: '8px',
    },
    }));

const FinancialSummaryComponent = ({ projectData }) => {
  const classes = useStyles();

  if (!projectData) {
    return <Typography>No Project Data Available</Typography>;
  }

  const currentContractData = projectData?.current_contract_summary ?? {};
  const penultimateContractData = projectData?.penultimate_contract_summary ?? {};

  if (!currentContractData || Object.keys(currentContractData).length === 0) {
    return <Typography style={{ textAlign: 'center', fontSize: '.9rem' }}>Contract Summary Under Construction 👷</Typography>;
  }

  // Extracted Data
  const cleanedContractData = {
    profit_value: currentContractData?.profit_data?.profit ?? 0,
    profit_percentage: (currentContractData?.profit_percentage ?? 0).toFixed(2),
    projected_contract: currentContractData?.revised_contract_value ?? 0,
    jtd_costs: currentContractData?.jtd_costs ?? 0,
  };

  const cleanedComparisonData = {
    profit_percentage_change: ((currentContractData?.profit_percentage ?? 0) - (penultimateContractData?.profit_percentage ?? 0)).toFixed(2),
    projected_contract_change: (penultimateContractData?.revised_contract_value_change_percentage ?? 0),
    jtd_costs_change: (currentContractData?.jtd_costs ?? 0) - (penultimateContractData?.jtd_costs ?? 0),
  };

  // Assign Colors
  const projectedContractChangeColor = getColorBasedOnValue(String(cleanedComparisonData.projected_contract_change ?? "0"));
  const profitPercentageChangeColor = getColorBasedOnValue(String(cleanedComparisonData.profit_percentage_change ?? "0"));

  // Extract Numeric Values
  const amountChangeValue = extractNumericValue(cleanedComparisonData.projected_contract_change);
  const profitPercentageChangeValue = extractNumericValue(cleanedComparisonData.profit_percentage_change);

  return (
    <CardContent className={classes.fullHeightContainer}>
      <Grid container spacing={1} style={{ width: '100%', justifyContent: 'center' }}>
        
        {/* Projected Contract */}
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.cardTitle}>Projected Contract</Typography>
          <Typography className={classes.cardContent}>{formatDollar(cleanedContractData.projected_contract)}</Typography>
          {amountChangeValue !== 0 && (
            <Typography
              style={{
                fontSize: 'small',
                color: projectedContractChangeColor,
                marginTop: '-5px',
              }}
            >
              {(cleanedComparisonData.projected_contract_change).toFixed(2)}%
            </Typography>
          )}
        </Grid>

        {/* Profit Value */}
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.cardTitle}>Profit Value</Typography>
          <Typography className={classes.cardContent}>{formatDollar(cleanedContractData.profit_value)}</Typography>
        </Grid>

        {/* JTD Costs */}
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.cardTitle}>JTD Costs</Typography>
          <Typography className={classes.cardContent}>{formatDollar(cleanedContractData.jtd_costs)}</Typography>
          {cleanedComparisonData.jtd_costs_change !== 0 && (
            <Typography
              style={{
                fontSize: 'small',
                color: projectedContractChangeColor,
                marginTop: '-5px',
              }}
            >
              {formatDollar(cleanedComparisonData.jtd_costs_change)}
            </Typography>
          )}
        </Grid>

        {/* Current Profit % */}
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <Typography className={classes.cardTitle}>Current Profit %</Typography>
          <Typography className={classes.cardContent}>{cleanedContractData.profit_percentage}%</Typography>
          {profitPercentageChangeValue !== 0 && (
            <Typography
              style={{
                fontSize: 'small',
                color: profitPercentageChangeColor,
                marginTop: '-5px',
              }}
            >
              {cleanedComparisonData.profit_percentage_change}%
            </Typography>
          )}
        </Grid>

      </Grid>
    </CardContent>
  );
};

export default FinancialSummaryComponent;
