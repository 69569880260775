import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";
import { formatPercentage } from "../utilityFunctions";
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

const ContractChangePercentage = ({ budgetData }) => {
    
    if (
        !budgetData ||
        !budgetData.penultimate_contract_summary) {
            return <Typography style={{textAlign: 'center'}} className="ppsf-mini"></Typography>
    }


    // Check for division by zero to prevent Infinity's
    if (!budgetData?.penultimate_contract_summary.revised_contract_value_change_percentage) {
        return <Typography style={{ textAlign: 'center' }} className="ppsf-mini">--</Typography>;
    }

    // Calculating Percent Change
    const percentChange = budgetData?.penultimate_contract_summary?.revised_contract_value_change_percentage

    // Flag to control arrow color
    const isPositive = percentChange >= 0;

    // Assign 'n define arrow color
    let color = percentChange >= 0 ? 'green' : 'red';
    const ArrowIcon = isPositive ? ArrowDropUpIcon : ArrowDropDownIcon;


    return (
        <Typography style={{ color: color, textAlign: 'center' }} className='ppsf-mini'>
            {percentChange.toFixed(2)}%
            <ArrowIcon fontSize="small" style={{ marginLeft: '2px', verticalAlign: 'top' }} />
        </Typography>
    );

}

export default ContractChangePercentage;