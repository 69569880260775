import React from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { Card, CardContent, Typography } from '@mui/material';
import { textAlign } from '@mui/system';
import { formatDollar, formatDollarShort } from '../utilityFunctions';

// const formatDollarShort = (value) => {
//     if (value >= 1000000) {
//       return `$${(value / 1000000).toFixed(0)}M`;
//   } else if (value >= 1000) {
//       return `$${(value / 1000).toFixed(0)}k`;
//   } else {
//       return new Intl.NumberFormat('en-US', { 
//           style: 'currency', 
//           currency: 'USD',
//           minimumFractionDigits: 0,
//           maximumFractionDigits: 0 
//       }).format(value);
//     }
//   }

// const formatDollar = (value) => {
//     return new Intl.NumberFormat('en-US', { 
//     style: 'currency', 
//     currency: 'USD',
//     minimumFractionDigits: 0,
//     maximumFractionDigits: 0 
//     }).format(value);
// };

const colorPalette = ['#004080', '#0059b3', '#5384b5', '#3399ff', '#8ac4ff', '#EEE5E9', '#c3d8e6', '#c7c5c5', '#e3e3e1'];

const ContractStructureChart = ({ budgetData }) => {

    if (!budgetData || !budgetData?.current_contract_summary?.contract_structure || typeof budgetData?.current_contract_summary?.contract_structure !== 'object') {
        return (
            <CardContent style={{ height: '200px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Typography className="key-project-info-body">--</Typography>
            </CardContent>
        );
    }

    const { Budget, Cost } = budgetData?.current_contract_summary?.contract_structure;

    if (!Budget || Object.keys(Budget).length === 0 || !Cost || Object.keys(Cost).length === 0) {
        return (
            <Typography className="titillium-web" color="error" style={{ textAlign: 'center', marginLeft: '25px', marginRight: '25px', marginTop: '20px', marginBottom: '10px', fontSize: '0.8rem' }}>
                "Budget Attributes" in your Procore job have NOT been assigned for this project.
                If unsure how to assign, <a href="https://scribehow.com/shared/Budget_Attributes__AdUrTAt6SzG6F-rwzvGEdw" target="_blank" rel="noopener noreferrer">click here.</a> <br /><br />
                If you recently added them in Procore, click "Project Settings" in the upper right > Select "Data Control Panel" > Click "Sync Now" in the "Budget Data Sync" section > Click "Close". Wait 5 minutes then refresh the PHD. <br /><br />
                
                Still having issues? Contact <a href="mailto:innovation@arcomurray.com">innovation@arcomurray.com</a>.
            </Typography>
        );
    }
    
    // Grab keys
    const revenueKeys = Object.keys(Budget);
    const costKeys = Object.keys(Cost)

    // Clean and combine Keys - Remove duplicates
    const cleanRevenueKeys = revenueKeys.filter(key => key && key.trim() !== '');
    const cleanCostKeys = costKeys.filter(key => key && key.trim() !== '');

    // Combine the filtered keys, ensuring no duplicates
    const uniqueKeys = Array.from(new Set([
        ...cleanRevenueKeys,
        ...cleanCostKeys.filter(key => !cleanRevenueKeys.includes(key)), // Only include cost keys that aren't already in revenue keys
    ]));


    const data = [
            {
                category: 'Revenue',
                ...Budget
            },
            {
                category: 'Cost',
                ...Cost
            }
        ]

    const formatLabel = (key) => {
        return key.replace(/ /g, '\n');
    };

    return(
        <CardContent style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', padding: 0 }}>
            <div style={{ height: '200px', width: '100%', maxWidth: '900px', maxHeight: '400px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ResponsiveBar
                data={data}
                keys={uniqueKeys}
                indexBy="category"
                layout="horizontal"
                margin={{ top: 20, right: 100, bottom: 80, left: 90 }}
                padding={0.3}
                borderRadius={1}
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                colors={({ id }) => colorPalette[uniqueKeys.indexOf(id) % colorPalette.length]}
                borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={{
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: '',
                    legendPosition: 'middle',
                    legendOffset: -40
                }}
                label={(d) => formatDollarShort(d.value)}
                labelSkipWidth={100}
                labelSkipHeight={100}
                labelTextColor={{ from: 'color', modifiers: [['brighter', 10]] }}
                legends={[
                    {
                        dataFrom: 'keys',
                        data: uniqueKeys.slice(0, Math.ceil(uniqueKeys.length / 2)).map((key, index) => ({
                            id: key,
                            label: formatLabel(key),
                            color: colorPalette[index % colorPalette.length],
                        })),
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 40,
                        itemsSpacing: 10,
                        itemWidth: 90,
                        itemHeight: 30,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 15,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                    {
                        dataFrom: 'keys',
                        data: uniqueKeys.slice(Math.ceil(uniqueKeys.length / 2)).map((key, index) => ({
                            id: key,
                            label: formatLabel(key),
                            color: colorPalette[(index + Math.ceil(uniqueKeys.length / 2)) % colorPalette.length],
                        })),
                        anchor: 'bottom',
                        direction: 'row',
                        justify: false,
                        translateX: 0,
                        translateY: 70,
                        itemsSpacing: 30,
                        itemWidth: 90,
                        itemHeight: 30,
                        itemDirection: 'left-to-right',
                        itemOpacity: 0.85,
                        symbolSize: 15,
                        effects: [
                            {
                                on: 'hover',
                                style: {
                                    itemOpacity: 1,
                                },
                            },
                        ],
                    },
                ]}
                theme={{
                    labels: {
                        text: {
                            fontSize: 13.5,
                            fill: '#000000',
                        },
                    },
                    axis: {
                        ticks: {
                            text: {
                                fontSize: 15,
                                fill: '#555555',
                                fontWeight: '500',
                                fontFamily: 'Titillium Web, sans-serif'
                            },
                        },
                        legend: {
                            text: {
                                fontSize: 17,
                                fill: '#333333',
                            },
                        },
                    },
                }}
                animate={true}
                motionStiffness={90}
                motionDamping={15}
                tooltip={(d) => (
                    <div style={{ padding: '5px', background: '#333', color: '#fff', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif' }}>
                        <strong>{d.indexValue} - {d.id}</strong>
                        <br />
                        Total: {formatDollar(d.value)}
                    </div>
                )}
            />
        </div>
    </CardContent>
    )
}

export default ContractStructureChart;