import {useState, useEffect} from 'react';
import { Grid, Card, CardContent, Typography, Tooltip } from '@material-ui/core';

import PercentComplete from './PercentCompleteComponent';
import ProfitPercentage from './CurrentProfitComponent';

import CurrentContractAmount from './CurrentContractAmountComponent';
import OGContractAmount from './OriginalContractAmountComponent';
import TotalEstimatedCosts from './TotalEstimatedCostsComponent';
import JTDCosts from './JTDCostsComponent';
import ContractChangePercentage from './ContractChangePercentageComponent';
import ProfitChangePercentage from './ProfitChangePercentageComponent';
import RevisedBudget from './RevisedBudgetComponent';
import ApprovedChangeEvents from './ApprovedChangeEventsComponent';
import ChangeEventRevenue from './ChangeEventRevenueComponent';
import { borderRadius } from '@mui/system';


const MasterContractInfoCard = ({ budgetData }) => {

    // useEffect to trigger re-render when delayed budgetData is received
        // API currently lagging projectData endpoint by ~20sec
    const [updatedBudgetData, setUpdatedBudgetData] = useState(null);

    useEffect(() => {
        if (budgetData) {
            setUpdatedBudgetData(budgetData);
        }
    }, [budgetData]);

    return (
        <CardContent style={{marginBottom: '-30px', marginTop: '-15px', marginLeft: '-20px', marginRight: '-20px'}}>
                <Grid container spacing={2} style={{ width: '100%', justifyContent: 'center' }}>
            {/* Original Contact  */}
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Original Contract</Typography>
                    <OGContractAmount budgetData={budgetData} />
                </Grid>
            {/* Current Contract */}
                <Grid item xs={4}>
                        <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Current Contract</Typography>
                        <span className='ppsf-container'>
                            <Tooltip
                                title={
                                    <div className="warning-icon-tooltip-content">
                                        <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>Sum of Original Contract and Approved Changes (Approved PCOs and PCCOs).</Typography>
                                    </div>
                                }
                            >
                                <span>
                                <CurrentContractAmount budgetData={budgetData} />
                                </span>
                            </Tooltip>
                        </span>
                    </Grid>
                {/* Revised Budget */}
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Projected Contract</Typography>
                    <Tooltip
                        title={
                            <div className="warning-icon-tooltip-content">
                                <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>Sum of Current Contract and Potential Changes (open PCOs and Change Event Revenue). The "Revised Budget" in Procore.</Typography>
                            </div>
                        }
                        >
                    <span>
                        <RevisedBudget budgetData={budgetData} />
                    </span>
                    </Tooltip>
                    <Tooltip
                                title={
                                    <div className="warning-icon-tooltip-content">
                                        <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>Change in Projected Contract Amount compared to the last snapshot.</Typography>
                                    </div>
                                }
                                >
                                <span>
                                    <ContractChangePercentage budgetData={budgetData} />
                                </span>
                            </Tooltip>

                </Grid>
                
                {/* Approved PCOS & PCCOS */}
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Approved Changes</Typography>
                    <Tooltip
                        title={
                            <div className="warning-icon-tooltip-content">
                                <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>Sum of all approved PCOs and PCCOs.</Typography>
                            </div>
                        }
                        >
                    <span>
                        <ApprovedChangeEvents budgetData={budgetData} />
                    </span>
                    </Tooltip>
                </Grid>
                {/* Change Event Revenue */}
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Potential Changes</Typography>
                    <Tooltip
                        title={
                            <div className="warning-icon-tooltip-content">
                                <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>Sum of Open PCOs and Change Event Revenue.</Typography>
                            </div>
                        }
                        >
                    <span>
                        <ChangeEventRevenue budgetData={budgetData} />
                    </span>
                    </Tooltip>
                </Grid>
                {/* Current Profit % */}
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Current Profit %</Typography>
                    <span className='ppsf-container'>
                        <ProfitPercentage budgetData={budgetData} />
                        <Tooltip
                            title={
                                <div className="warning-icon-tooltip-content">
                                    <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>Change in profit over the past month.</Typography>
                                </div>
                            }
                        >
                        <span>
                            <ProfitChangePercentage budgetData={budgetData} />
                        </span>
                        </Tooltip>
                    </span>
                </Grid>
               
                {/* JTD Costs */}
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>JTD Costs</Typography>
                    <JTDCosts budgetData={budgetData} />
                </Grid>

               {/* Estimated Costs */}
                <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Estimated Costs</Typography>
                    <TotalEstimatedCosts budgetData={budgetData} />
                </Grid>

                 {/* Percent Complete */}
                 <Grid item xs={4}>
                    <Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>Percent Complete</Typography>
                    <Tooltip
                            title={
                                <div className="warning-icon-tooltip-content">
                                    <Typography style={{fontSize: '1rem', padding: '5px', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>Calculated as JTD Costs/Estimated Costs.</Typography>
                                </div>
                            }
                        >
                        <span>
                            <PercentComplete budgetData={budgetData} />
                        </span>
                    </Tooltip>
                </Grid>
               
            
         </Grid>
        </CardContent>
    )
}


export default MasterContractInfoCard;