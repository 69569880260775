import React, {useState, useEffect, useRef} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import '../static/project-dashboard-styles.css';
import { makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@mui/icons-material/Email';
import WarningIcon from '@mui/icons-material/Warning';
import Tooltip from '@mui/material/Tooltip';
import concurLogo from '../static/concur_logo.svg';
import bvtLogo from '../static/bvt_logo_revised2.svg';
import viewpointButtonLogo from '../static/viewpointButtonLogoActive.svg';
import CloseIcon from "@mui/icons-material/Close";
import Groups2Icon from '@mui/icons-material/Groups2';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { toast } from 'react-hot-toast';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import ThreePIcon from '@mui/icons-material/ThreeP';
import { Grid, Card, CardContent, Typography, useMediaQuery, Dialog, DialogActions, DialogContent, DialogContentText, IconButton, DialogTitle, Button } from '@mui/material';
import { Container } from 'react-bootstrap';

// COMPONENT IMPORTS
import HeaderComponent from './HeaderComponent';
import ProjectTeamTable from './ProjectViewComponents/ProjectTeamComponent';
import ProgressTrackerTable from './ProjectViewComponents/ProgressTrackerComponent';
import OverUnderSummaryChart from './ProjectViewComponents/OverUnderComponent';
import PCOTypeSummary from './ProjectViewComponents/PCOTypeSummaryComponent';
import PaymentsAndInvoicingTable from './ProjectViewComponents/PaymentsAndInvoicingTableComponent';
import CFASplit from './ProjectViewComponents/CFASplitComponent';
import ProjectLocationMap from './ProjectViewComponents/ProjectLocationComponent';
import InfoIconButton from './ProjectViewComponents/InfoIconButtonComponent';
import TablePopoutButton from './ProjectViewComponents/TablePopoutIconComponent';
import ContractStrutureChart from './ProjectViewComponents/ContractStructureComponent';
import ConcurExpenseTracker from './ProjectViewComponents/ConcurExpenseTracker';
import MasterProjectInfoCard from './ProjectViewComponents/MasterProjectInformationCard';
import RevenueSpreadCard from './ProjectViewComponents/RevenueSpreadCard';
import MasterContractInfoCard from './ProjectViewComponents/MasterContractInformationCard';
import ProfitDataChart from './ProjectViewComponents/ProfitChartComponent';
import ARCOContingencyChart from './ProjectViewComponents/ArcoContingencyComponent';
import CostsVsBillingsChart from './ProjectViewComponents/CostsAndBillingsComponent';
import BVTTablesComponent from './ProjectViewComponents/BVTTablesComponent';
import ViewpointExpenseTracker from './ViewpointExpenseTracker';
import MilestoneProgressBar from './ProjectViewComponents/ProgressMilestonesComponent';
import QuickLinks from './ProjectViewComponents/QuickLinksComponent';
import TooltipButton from './ProjectViewComponents/TooltipButton';
import { getRequiredRoles, toastStyle } from '../Containers/continerUtilityFunctions';
import {calculateAveragePaymentAge} from './utilityFunctions';

import {
    fetchProjectData,
    fetchProjectSettings,
    fetchProjectInvoiceData,
    fetchProjectTeam,
    fetchProjectBudgetData,
	fetchAccessToProject,
	fetchProjectsAndCompanies,
	fetchRevenueSpread,
	authCheck, 
} from '../Containers/api';
import { setAuthenticated } from '../../../store/slices/globalSlice';
import { setProjectData, setBudgetData, setTeamData, setSelectedProjectId, setSelectedProject, setRevenueSpreadData,setInvoiceData, setSettingsData, resetProjectState, setProjects, setCompanies, setSelectedCompany } from '../../../store/slices/projectSlice';

const gmapsAPI = process.env.REACT_APP_GOOGLE_API_KEY

// Email All Button Styling
const useStyles = makeStyles((theme) => ({
}));

// Define Redux Selectors
const useReduxSelectors = () => {
	const projectData = useSelector((state) => state.project.projectData);
	const authenticated = useSelector((state) => state.global.authenticated);
	const budgetData = useSelector((state) => state.project.budgetData);
	const teamData = useSelector((state) => state.project.teamData);
	const selectedProject = useSelector((state) => state.project.selectedProject);
	const selectedProjectId = useSelector((state) => state.project.selectedProjectId);
	const revenueSpreadData = useSelector((state) => state.project.revenueSpreadData);
	const invoiceData = useSelector((state) => state.project.invoiceData)
	const tab = useSelector((state) => state.global.tab);

	return { projectData, authenticated, budgetData, teamData, selectedProjectId, selectedProject, revenueSpreadData, invoiceData, tab };
};

const ProjectDashboard = ({}) => {
	const classes = useStyles();
	// Redux - useDispatch and Destructure Redux Selectors
	const dispatch = useDispatch();
	const { authenticated, selectedCompany, projectData, budgetData, teamData, selectedProjectId, selectedProject, revenueSpreadData, invoiceData, tab } = useReduxSelectors();


	const [hasAccess, setHasAccess] = useState(null);
	const [accessPossible, setAccessPossible] = useState(null);
	const [accessChecked, setAccessChecked] = useState(false);
	const [noAccessDialogOpen, setNoAccessDialogOpen] = useState(false);
	const [sourceProcore, setSourceProcore] = useState('procore')
	const [selectedSnapshots, setSelectedSnapshots] = useState([]);
	const [initialSnapshots, setInitialSnapshots] = useState([]);
	
	const [firstSnapshot, setFirstSnapshot] = useState('');
	const [secondSnapshot, setSecondSnapshot] = useState('');
	const [isLoadingSnapshots, setIsLoadingSnapshots] = useState(true);
	// let selectedCompany = []

	const fullResetView = () => {
		dispatch(setSelectedProject(null));
		dispatch(setSelectedProjectId(null));
		dispatch(setRevenueSpreadData({}));
		dispatch(setProjectData({}));
		dispatch(setBudgetData([]));
		setSelectedSnapshots([]);
		setInitialSnapshots([]);
		dispatch(setInvoiceData([]));
		dispatch(setSettingsData([]));
		dispatch(setTeamData([]));
		setShowAllProfitData(false);
		setShowAllContData(false);
	}

	// Call Function when we switch from projectView to portfolioView
	useEffect(() => {
		if (tab === 'portfolio') {
			fullResetView();
		} 
	}, [tab]);

	// Clears everything in Project View when selectedProjectID is set to null (no proj selected)
	useEffect(() => {
		if (selectedProjectId === null) {
			dispatch(setProjectData({}));
			dispatch(setBudgetData([]));
			setSelectedSnapshots([]);
			setInitialSnapshots([]);
			dispatch(setInvoiceData([]));
			dispatch(setSettingsData([]));
			dispatch(setTeamData([]));
			dispatch(setRevenueSpreadData({}));
		}
	}, [selectedProjectId])

	// Refs for image button logic
	const bvtTableIconPopout = useRef(null);
	const concurTableIconPopout = useRef(null);
	const viewpointTableIconPopout = useRef(null);

	const handleBVTLogoClick = () => {
		if (bvtTableIconPopout.current) {
			bvtTableIconPopout.current.triggerClick();
		}
	};

	const handleConcurLogoClick = () => {
		if (concurTableIconPopout.current) {
			concurTableIconPopout.current.triggerClick();
		}
	};

	const handeViewpointLogoClick = () => {
		if (viewpointTableIconPopout.current) {
			viewpointTableIconPopout.current.triggerClick();
		}
	};

	// Logic to Control how split components (6/6) are laid out when screen is shrunk
	const isWideScreen = useMediaQuery('(min-width:1900px)');
	const customGripProps = isWideScreen ? {xs: 6} : {xs: 12}

	// Project Team -- View ALL Feature
	const [isRosterModalOpen, setIsRosterModalOpen] = useState(false);
	const openRosterModal = () => setIsRosterModalOpen(true);
	const closeRosterModal = () => setIsRosterModalOpen(false);  
	const roles = teamData || [];
	
	// Email All Handler
	const handleEmailAll = (emails) => {
		const validEmails = emails.filter(Boolean);
		if (validEmails.length === 0) {
			alert("No team members have email addresses.");
			return;
		}
		const subject = encodeURIComponent(`Question on ${selectedProject.project_number} ${selectedProject.name}`);
		const mailtoLink = `mailto:${validEmails.join(',')}`+`?subject=${subject}`;
		window.location.href = mailtoLink;
	};

	// Graph Logic
	const [showAllContData, setShowAllContData] = useState(false);
	const [showAllProfitData, setShowAllProfitData] = useState(false);

	const [moreThanSixMonthsData, setMoreThanSixMonthsData] = useState(false)

	useEffect(() => {
		if (!budgetData || !budgetData.profit_data || budgetData.profit_data.length === 0) return false;

		const earliestDate = new Date(budgetData.profit_data[0].snapshot_date);
		const sixMonthsAgo = new Date();
		sixMonthsAgo.setMonth(sixMonthsAgo.getMonth() - 6);

		if (earliestDate < sixMonthsAgo) {
			setMoreThanSixMonthsData(true)
		} else {
			setMoreThanSixMonthsData(false)
		}
	}, [selectedProjectId, selectedProject])
	
	// ============ ACCESS CHECK WHEN PROJECTID CHANGES =============
	// When selectedProjectId changes, we check access.
	useEffect(() => {
		if (!selectedProjectId || !authenticated) {
			// If no project selected, reset access states
			setHasAccess(null);
			setAccessPossible(null);
			setAccessChecked(false);
			return;
		}

		const fetchAccessToProjectAsync = async () => {
			try {
				const resData = await fetchAccessToProject(selectedProjectId);
				if (resData.access === true && resData.access_possible === true) {
					setHasAccess(true);
					setAccessPossible(true);
					toast.success("Access granted for this project!", toastStyle());
				} else {
					if (resData.access === false && resData.access_possible === true) {
						toast.error("You do not have access to this project, but access is possible.", toastStyle());
						setHasAccess(false);
						setAccessPossible(true);
						setNoAccessDialogOpen(true);
					} else if (resData.access === false && resData.access_possible === false) {
						toast.error("Access to this project is not possible given your current access priviledges.", toastStyle());
						setNoAccessDialogOpen(true);
						setHasAccess(false);
						setAccessPossible(false);
					}
				}
				setAccessChecked(true);		
			} catch (error) {
				console.error("Error checking user's access:", error);
			}
		};
		fetchAccessToProjectAsync();
	}, [selectedProjectId]);

const handleCloseNoAccessDialog = () => {
	setNoAccessDialogOpen(false);
};

	// Setting Default snapshots for over/under details call
	useEffect(() => {
		if (selectedSnapshots && selectedSnapshots.length > 0) {
			if (!firstSnapshot && selectedSnapshots[0]) {
				setFirstSnapshot(selectedSnapshots[0]);
			}

			if (!secondSnapshot && selectedSnapshots.length > 1) {
				setSecondSnapshot(selectedSnapshots[selectedSnapshots.length - 1]);
			}
		}
	}, [selectedSnapshots, firstSnapshot, secondSnapshot]);

	// Combined Fetch for grabbing all initial data triggered off of projectID change
	// Combined Fetch for initial data
	useEffect(() => {
		if (!selectedProjectId || !accessChecked) return;
		if (hasAccess === false) {
			return; // Do not run any API calls
		}
		if (hasAccess === true) {
			if (selectedProjectId) {
				const fetchData = async () => {
					try {
						const projectData = await fetchProjectData(selectedProjectId);
						const settingsData = await fetchProjectSettings(selectedProjectId);
						const invoiceData = await fetchProjectInvoiceData(selectedProjectId);

						// Update project data
						if (projectData) {
							dispatch(setProjectData(projectData));
						}

						// Update snapshots
						if (settingsData.snapshots) {
							const allSnapshots = Object.values(settingsData.snapshots).flat();
							setInitialSnapshots(allSnapshots);
							const initialSelectedSnapshots = allSnapshots.filter(snapshot => snapshot.selected);
							setSelectedSnapshots(initialSelectedSnapshots);
						}
						// Update Settings
						if (settingsData) {
							dispatch(setSettingsData(settingsData));
						} else {
							// setSettingsData([])
						}

						// Handle Invoice Data
						if (invoiceData.data) {
							dispatch(setInvoiceData(invoiceData.data));
						} else {
							dispatch(setInvoiceData([]));
						}

					} catch (error) {
						console.error('Error fetching project data:', error);
						dispatch(setInvoiceData([]));
					}
				};

				// Clearing Previous Budget Data
				dispatch(setBudgetData([]));
				fetchData();
			}
		}
	}, [selectedProjectId, accessChecked, hasAccess]);

	// Fetch Team Data
	useEffect(() => {
		if (!selectedProjectId || !accessChecked || hasAccess === false) {
			return;
		}
		if (hasAccess === true) {
			const fetchTeamData = async () => {
				try {
					const teamData = await fetchProjectTeam(selectedProjectId);

					if (Array.isArray(teamData)) {
						dispatch(setTeamData(teamData));
					} else {
						console.error("Invalid team data format:", teamData);
					}

				} catch (error) {
					console.error("Error fetching team data:", error);
				}
			};

			fetchTeamData();
		}
	}, [selectedProjectId, accessChecked, hasAccess]);

	// Fetch Revenue Spread Data
	useEffect(() => {
		if (!selectedProjectId || !accessChecked || hasAccess === false) {
			return;
		}
		if (hasAccess === true) {
			const fetchRevenueSpreadDataAsync = async () => {
				try {
					const revenueSpreadData = await fetchRevenueSpread(selectedProjectId);
					if (revenueSpreadData) {
						dispatch(setRevenueSpreadData(revenueSpreadData || []));
					}
				} catch (error) {
					console.error("Error fetching revenue spread data:", error);
				}
			};
			fetchRevenueSpreadDataAsync();
		}
	}, [selectedProjectId, accessChecked, hasAccess]);

// Separate useEffect for fetching budgetData whenever selectedSnapshots or selectedProjectId changes -- still getting called multiple times, but getting there
// Budget Data Fetch
useEffect(() => {
    if (!selectedProjectId || !accessChecked || hasAccess === false || selectedSnapshots.length === 0) {
        return;
    }

    if (hasAccess === true && selectedProjectId && selectedSnapshots.length > 0) {
        const fetchBudgetData = async () => {
            try {
                const data = await fetchProjectBudgetData(selectedProjectId);
                if (data) {
                    dispatch(setBudgetData(data || []));
                } else {
                    console.error('Failed to fetch project budget data from HUB API:', data.message);
                }
            } catch (e) {
                console.error('Error fetching budget data:', e);
            }
        };

        fetchBudgetData();
    }
}, [selectedSnapshots, selectedProjectId, accessChecked, hasAccess, projectData]);

// Check for missing project team members
let missingProjectTeam = false
let missingRoles = []

if (budgetData && Array.isArray(teamData)) {
	for (const role of getRequiredRoles()) {
		const roleExists = teamData.some(member => member.role === role);
		if (!roleExists) {
			missingRoles.push(role); 
			missingProjectTeam = true;
		}
	}
}
// Set Project Procore ID for Warning Icon ToolTip Links
let procoreID = null
if (projectData) {
	procoreID = projectData?.project_data?.procore_id
}

	const renderProjectView = () => (
		<Grid id="main-container-proj" container spacing={1} style={{marginTop: '15px'}}>
		 {/* Column 1 */}
		<Grid item xs={12} md={4}>
			<Grid container spacing={1.5} style={{ width: '100%', justifyContent: 'center' }}>
				<Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
					<Card className="card-content-container">
						<CardContent className="card-content">
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px'}}>
						<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top'}}>Project Information Summary</Typography>
						<div className="project-team-icon-container">
								
								{/* Warning Icon with Tooltip - shown when we're past subst comp but not in Warranty or Stopped Work stage */}
								{projectData.project_data &&
									new Date(projectData.project_data.projected_finish_date) < new Date() &&
									projectData.project_data.stage !== "Warranty" &&
									projectData.project_data.stage !== "Stopped Work" && (
										<TooltipButton
											title={`The projected finish date has passed, and the project is not in "Warranty" or "Stopped Work" stage. You can update this in your Procore admin tool.`}
											buttonText="Open Procore Admin Tool"
											onClick={() => { window.open(`https://app.procore.com/${procoreID}/project/admin`, '_blank'); }}
											iconColor="orange"
										/>									
									)}

								{/* Additional case: Warranty stage and more than 1 year from projected finish date. Should be complete */}
								{projectData.project_data &&
									projectData.project_data.stage === "Warranty" &&
									new Date() > new Date(new Date(projectData.project_data.projected_finish_date).setFullYear(new Date(projectData.project_data.projected_finish_date).getFullYear() + 1)) && (
											<TooltipButton
												title={`The project entered it's "Warranty" phase over a year ago. Please review and mark this project as "Inactive" in the Procore Admin Tool.`}
                                                buttonText="Open Procore Admin Tool"
                                                onClick={() => { window.open(`https://app.procore.com/${procoreID}/project/admin`, '_blank'); }}
                                                iconColor="orange"
                                            />									
										)}
									<InfoIconButton
										projectData={projectData}
										source="project-info-summary"
										sourceProcore={sourceProcore}
									/>
							</div>
					</div>

							<MasterProjectInfoCard 
								projectData={projectData}
								budgetData={budgetData} 
								/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
				<Card className="card-content-container">
					<CardContent className="card-content">
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-10px', marginTop: '-15px'}}>
						<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Project Team</Typography>
						<div className="project-team-icon-container">
							{/* Warning icon for missing team members */}
							{missingProjectTeam && selectedProject && (
								<Tooltip
									title={
										<div className='warning-icon-tooltip-content'>
											<p style={{margin: 0}}>
											Some project team members have missing information. Please review your Procore admin tool and update.
											</p>
										
										<Button 
										variant="contained" 
										className='smaller-procore-button'
										size="small"
										onClick={() => { 
											window.open(`https://app.procore.com/${procoreID}/project/admin`, '_blank');  
										}}
										style={{ marginTop: '8px', textAlign: 'center'}}
									>
										Open Procore Admin Tool
									</Button>
								</div>
									}
									componentsProps={{
										tooltip: {
											sx: {
												fontSize: '1rem',
												padding: '10px',
											},
										},
									}}
								>
									<WarningIcon style={{ color: 'orange', marginRight: '10px', verticalAlign: 'middle' }} />
								</Tooltip>
							)}
							{/* Email All Button */}
							{selectedProject && (
								<Button
									variant="contained"
									startIcon={<Groups2Icon/>}
									color="primary"
									style={{
										backgroundColor: "#007bff",
										color: "#fff",
									}}
									onClick={openRosterModal}
									className='smaller-primary-buttom'
									>
									Full Team
								</Button>
							)}
								<InfoIconButton
									projectData={projectData}
									source="procore-team"
									sourceProcore={sourceProcore}
								/>
						</div>
					</div>
					<ProjectTeamTable
						selectedProject={selectedProject}
						teamData={teamData}
						reset={tab}
						/>
					{/* Full Team Dialog */}
					{selectedProject && (
						<Dialog
						open={isRosterModalOpen}
						onClose={closeRosterModal}
						maxWidth="md"
						style={{
							overflowY: "scroll",
						}}
						fullWidth
						>
						{/* Dialog's Close Icon */}
						<IconButton
							onClick={closeRosterModal}
							style={{
							color: "#000",
							position: "absolute",
							top: "8px",
							right: "8px",
							}}
						>
							<CloseIcon />
						</IconButton>

						<DialogTitle>Full Project Team</DialogTitle>
						<DialogContent>
							<Grid container spacing={2}>
								{roles.length > 0 ? (
									roles.map((role, index) => (
										<Grid 
											item 
											xs={12} 
											key={index} 
											className="project-teammember-info-container"
										>
											<div
												className="project-teammember-card"
												style={{
													display: "flex",
													alignItems: "center",
													padding: "10px",
													border: "1px solid #ccc",
													borderRadius: "5px",
													boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
													marginBottom: "3px",
												}}
											>
												{/* Profile Picture */}
												{role.profile_picture ? (
													<img
														src={role.profile_picture}
														alt={`${role.name || "Role"}'s profile`}
														style={{
															width: "50px",
															height: "50px",
															borderRadius: "50%",
															marginRight: "15px",
															objectFit: "cover",
														}}
													/>
												) : (
													<AccountCircleIcon
														style={{
															fontSize: "50px",
															color: "#ccc",
															marginRight: "15px",
														}}
													/>
												)}

												{/* Role Name and Email */}
												<div 
													className="project-team-info" 
													style={{ 
														display: "flex", 
														flexDirection: "column" 
													}}
												>
													<div style={{display: 'flex', flexDirection: 'column', gap: '2px'}}>
													<Typography
														variant="subtitle1"
														className="titillium-web"
														style={{ 
															marginBottom: "3px",
															color: '#093d61'
														}}
													>
														{role.role}
													</Typography>
													<span style={{display: 'flex', flexDirection: 'row'}}>
													<Typography
														variant="subtitle1"
														className="titillium-web"
														style={{ 
															marginBottom: "3px" 
														}}
													>
														{role.name}
													</Typography>
													<Typography 
														variant="body2" 
														style={{ color: "#555" }}
													>
														{role.email && (
														<>
															<Tooltip
																title={
																	<div className="warning-icon-tooltip-content">
																		<Typography style={{fontSize: '1rem', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>{`Teams ${role.name}`}</Typography>
																	</div>
																}
															>
															<a className="teams-contact-button" href={`https://teams.microsoft.com/l/chat/0/0?users=${role.email}`} target="_blank" rel="noopener noreferrer">
																<IconButton size="small" className={classes.iconButton}>
																<ThreePIcon className={classes.iconButton} style={{ fontSize: "18px", paddingLeft: '0px' }}/>
																</IconButton>
															</a>
															</Tooltip>

															<Tooltip
																title={
																	<div className="warning-icon-tooltip-content">
																		<Typography style={{fontSize: '1rem', borderRadius: '3px', fontFamily: 'Titillium Web, sans-serif'}}>{`Email ${role.name}`}</Typography>
																	</div>
																}
															>
															<a className="teams-contact-button" href={`mailto:${role.email}`} target="_blank" rel="noopener noreferrer">
																<IconButton size="small" className={classes.iconButton}>
																<EmailIcon className={classes.iconButton} style={{ fontSize: "1.20rem", marginLeft: '-2px' }}/>
																</IconButton>
															</a>
															</Tooltip>
														</>
														)}
													</Typography>
													</span>
													</div>
												</div>
											</div>
										</Grid>
									))
								) : (
									<Typography>No team members found.</Typography>
								)}
							</Grid>
						</DialogContent>
						{/* Dialog Actions */}
						<DialogActions>
							<Button
							className="procore-button"
							href={`https://app.procore.com/${projectData?.project_data?.procore_id}/project/directory/configure_tab`}
							target="_blank"
							>
							Update in Procore
							</Button>
							<Button
								variant="contained"
								color="primary"
								startIcon={<EmailIcon/>}
								onClick={() =>
									handleEmailAll(
									roles.map((role) => role.email).filter(Boolean), // Valid emails only
									selectedProject?.project_number,
									selectedProject?.name
									)
								}
								>
								Email All
								</Button>
						</DialogActions>
						</Dialog>
					)}
					</CardContent>
				</Card>
				</Grid>
				<Grid item {...customGripProps} style={{ display: 'flex', flexDirection: 'column' }}>
					<Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
						<CardContent className="card-content">
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px', padding: '0px 15px'}}>
								<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>CFA Split</Typography>
									<InfoIconButton
											projectData={projectData}
											source="salesforce"
										/>
							</div>
							<div style={{ height: '100%', maxHeight:'120px'}}>
								<CFASplit projectData={projectData} />
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item {...customGripProps} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
						<CardContent className="card-content" style={{ flex: 1 }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px', padding: '0px 15px'}}>
							<Typography variant="subtitle1" className="card-title">
								Project Milestones
							</Typography>
						<InfoIconButton
							projectData={projectData}
							source='percent-complete'
						 />
						</div>
							<div style={{ height:'200px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '-45px' }}>
							<MilestoneProgressBar 
									projectData={projectData}
								/>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
				<Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
					<CardContent className="card-content" style={{ flex: 1}}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px', padding: '0px 15px'}}>
					<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top'}}>Project Location</Typography>
					<InfoIconButton 
						projectData={projectData}
						source="project-location"
					/>
					</div>
						<ProjectLocationMap
							projectData={projectData}
							gmapsAPI={gmapsAPI}
						/>
					</CardContent>
				</Card>
				</Grid>
			</Grid>
			</Grid>

		{/* Column 2 */}
			<Grid item xs={12} md={4}>
			<Grid container spacing={1.5} style={{ width: '100%', justifyContent: 'center' }}>
				<Grid item xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
					<Card className="card-content-container">
						<CardContent className="card-content">
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px'}}>
								<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
									Contract Financial Summary
								</Typography>
								<InfoIconButton
									projectData={projectData}
									source="contract-financial-summary"
								/>
							</div> 
							<MasterContractInfoCard
								projectData={projectData}
								budgetData={budgetData}
							/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
					<Card className="card-content-container">
						<CardContent className="card-content">
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: '0px', marginRight: '0px', marginBottom: '0px', marginTop: '-12px', }}>
								<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
									Budget Variance
								</Typography>
								<div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}>
								<TablePopoutButton
									ref={bvtTableIconPopout}
									budgetData={budgetData}
									tableComponent={BVTTablesComponent}
									title={'Budget Variance Data'}
									selectedProject={selectedProject}
									source="bvt-table"
									selectedSnapshots={selectedSnapshots}
									projectId={selectedProjectId}
								/>
								<InfoIconButton 
									projectData={projectData}
									source="contract-financial-summary"
								/>
								</div>
							</div>
							{selectedProject && (
								<>
									<div style={{ textAlign: 'center', marginTop: '-10px' }}>
										<ContractStrutureChart budgetData={budgetData} />
									</div>
									{(() => {
										const { Budget, Cost } = budgetData?.current_contract_summary?.contract_structure || {};
										if (!Budget || Object.keys(Budget).length === 0 || !Cost || Object.keys(Cost).length === 0) {
											return null;
										}
										return (
											<span
											style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
											onClick={handleBVTLogoClick}
											src={bvtLogo}
											>
											<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'center' }}>
												Expand to explore the budget variances for this project.
											</Typography>
											{/* <OpenWithIcon fontSize="small" style={{ marginLeft: '3px', color: '#093d61', verticalAlign: 'middle', marginTop: '-4px'}} /> */}
											</span>
										);
										})()}
								</>
							)}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12}>
				<Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
						<CardContent className="card-content" style={{ flex: 1 }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '0px', marginTop: '-12px', padding: '0px 15px'}}>
								<Typography variant="subtitle1" className="card-title">Profit</Typography>
								{budgetData && budgetData.profit_data && (
									<div>
										<Button variant="contained" className="smaller-primary-button" 
											onClick={() => setShowAllProfitData(!showAllProfitData)}>
											{showAllProfitData ? 'Show Last 6 Months' : 'Show All Data'}
										</Button>
										<InfoIconButton
										projectData={projectData}
										source="profit-chart"
										/>
									</div>
								)}
							</div>
						<div style={{width: '100%', height: '300px', marginLeft: '10px', marginRight: '10px', marginTop: '10px', marginBottom: '-10px'}}>
							<ProfitDataChart 
								budgetData={budgetData} 
								showAllProfitData={showAllProfitData}
							/>
						</div>
						</CardContent>
					</Card>
				</Grid>

				<Grid item xs={12}>
				<Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
						<CardContent className="card-content" style={{ flex: 1 }}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '15px', marginTop: '-12px', padding: '0px 15px'}}>
								<Typography variant="subtitle1" className="card-title">
									Project Contingency (Beta)
								</Typography>
								{budgetData && budgetData.contingency_data && (
									<div>
									<Button
										variant="contained"
										className="smaller-primary-button"
										onClick={() => setShowAllContData(!showAllContData)}
									>
										{showAllContData ? 'Show Last 6 Months' : 'Show All Data'}
									</Button>
									 <InfoIconButton
									 projectData={projectData}
									 source="contingency-chart"
								 />
								 </div>
								)}
							</div>
							<div style={{width: '100%', height: '300px', marginLeft: '10px', marginRight: '10px', marginTop: '10px', marginBottom: '-10px'}}>
								<ARCOContingencyChart
								budgetData={budgetData}
								showAllContData={showAllContData}
								/>
							</div>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} style={{display: 'none'}}>
				<Card className="card-content-container">
				<CardContent className="card-content" style={{marginLeft: "20px", marginRight: "20px"}}>
					<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>PCO Type Summary (Work in Progress)</Typography>
					{/* <div className="banner-container">
					<Typography className="red-datawarehouse-banner">
						In Development
					</Typography>
					</div> */}
					
					<PCOTypeSummary 
						budgetData={budgetData}
					/> 
					</CardContent>
				</Card>
				</Grid>
			   
			</Grid>
			</Grid>

		{/* Column 3 */}
			<Grid item xs={12} md={4}>
			<Grid container spacing={1.5}>
		 {/* QUICK LINKS -- TO FINISH */}
			<Grid item xs={12}>
				<Card className="card-content-container">
					<CardContent className="card-content" style={{maxHeight: '150px', marginBotom: '-45px'}}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px', padding: '0px'}}>
						<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top'}}>Project Quick Links</Typography>
					</div>
					<QuickLinks
						projectData={projectData}
						selectedProjectId={selectedProjectId}
					/>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card className="card-content-container">
					<CardContent className="card-content" style={{maxHeight: '360px', marginBotom: '-45px'}}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px', padding: '0px 15px'}}>
						<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top'}}>Revenue Spread by Years</Typography>
						<InfoIconButton
								projectData={projectData}
								source="salesforce"
							/>
					</div>
					<RevenueSpreadCard 
						revenueSpreadData={revenueSpreadData}
					/>
					</CardContent>
				</Card>
			</Grid>
			<Grid item xs={12}>
				<Card className="card-content-container">
				<CardContent className="card-content" style={{maxHeight: '225px', marginBotom: '-35px'}}>
				<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px'}}>
					<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top'}}>Cost vs Billings</Typography>
					<InfoIconButton 
						projectData={projectData}
						source="costs-and-billings"
					/>
					</div>
					<CostsVsBillingsChart
						budgetData={budgetData}
						invoiceData={invoiceData}
					/>
					</CardContent>
				</Card>
				</Grid>
				<Grid item xs={12}>
					<Card className="card-content-container">
						<CardContent className="card-content" style={{marginBottom: '-15px'}}>
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '-15px', marginTop: '-15px', paddingLeft: '20px', paddingRight: '20px'}}>
								<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>
									Payment & Invoicing History (Beta)
								</Typography>
								{/* <div className="red-datawarehouse-banner" style={{ position: 'relative', marginTop: '15px' }}>
									Awaiting VP Data
								</div> */}
								<TablePopoutButton
									budgetData={budgetData}
									tableComponent={PaymentsAndInvoicingTable}
									title={'Payment & Invoicing History (Beta)'}
									invoiceData={invoiceData}
									selectedProject={selectedProject}
									source="payment-invoicing"
								/>
							</div>
							<PaymentsAndInvoicingTable 
								isModal={false}
								invoiceData={invoiceData}
								selectedProject={selectedProject}
							/>							
							{/* <Typography className="titillium-web-base" style={{textAlign: 'center', marginTop: '-8px'}}>
								Average Payment Time: {calculateAveragePaymentAge(invoiceData)} days
							</Typography> */}
							{/* <Typography className="titillium-web-base" style={{textAlign: 'center', marginTop: '-8px'}}>
								Note: AMI is working with ABS-IT to improve the reliability of this data.
							</Typography> */}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6}>
					<Card className="card-content-container">
						<CardContent className="card-content">
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-15px' }}>
								<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Concur Tracker</Typography>
								<TablePopoutButton 
									ref={concurTableIconPopout}
									tableComponent={ConcurExpenseTracker}
									concurUrl={projectData?.project_data?.concur_expense_tracker_url}
									title={'Concur Expense Tracker'}
									source="concur-table"
									selectedProject={selectedProject}
									projectData={projectData}
									disabled={!projectData || Object.keys(projectData).length === 0}
									onClick={(e) => {
										if (!projectData || Object.keys(projectData).length === 0) {
											e.preventDefault();
											return;
										}
									}}
								/>
							</div>
							{selectedProject && (
								<>
									<div style={{ textAlign: 'center', margin: '20px 0' }}>
										<img 
											src={concurLogo} 
											alt="Concur Expense Tracker Image" 
											style={{ maxWidth: '15%'}}
											className="table-logo-button"
											onClick={handleConcurLogoClick}
											ref={concurTableIconPopout}
											disabled={!projectData || Object.keys(projectData).length === 0}
										/>
									</div>
									<Typography className="card-title" style={{ textAlign: 'center', verticalAlign: 'top', paddingBottom: '10px' }}>Expand to view the Concur expenses charging the project.</Typography>
								</>
							)}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={6}>
					<Card className="card-content-container">
						<CardContent className="card-content">
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '-15px' }}>
								<Typography variant="subtitle1" className="card-title">Keystyle Tracker</Typography>
								<TablePopoutButton 
									ref={viewpointTableIconPopout}
									tableComponent={ViewpointExpenseTracker}
									viewpointURL={null}
									title={'Keystyle Hours Tracker'}
									source="viewpoint-table"
									selectedProject={selectedProject}
									disabled={!projectData || Object.keys(projectData).length === 0}
								/>
							</div>
							{selectedProject && (
								<>
									<div style={{ textAlign: 'center', margin: '20px 0', opacity: '0.5',  cursor: "not-allowed" }}>
									<img 
										src={viewpointButtonLogo} 
										alt="Viewpoint Keystyle Image" 
										style={{ maxWidth: '15%', cursor: "not-allowed"}}
										className="table-logo-button"
										onClick={handeViewpointLogoClick}
										disabled={!projectData || Object.keys(projectData).length === 0}
									/>
									</div>
									<Typography className="card-title" style={{ textAlign: 'center', verticalAlign: 'center', paddingBottom: '10px' }}>In Development - Keystyle data coming soon.</Typography>
								</>
							)}
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
					<Card className="card-content-container" style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
					<CardContent className="card-content" style={{marginBottom: '-20px'}}>
						<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px", marginBottom: '-15px', marginTop: '-15px'}}>
							<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Top Budget Pickups/Busts</Typography>
							<TablePopoutButton 
								budgetData={budgetData}
								tableComponent={OverUnderSummaryChart}
								selectedSnapshots={selectedSnapshots}
								title={'Top Budget Pickups & Busts'}
								source='trade over/under'
								selectedProject={selectedProject}
								firstSnapshot={firstSnapshot}
								secondSnapshot={secondSnapshot}
								setFirstSnapshot={setFirstSnapshot}
								setSecondSnapshot={setSecondSnapshot}
								isLoadingSnapshots={isLoadingSnapshots}
							/>
						</div>
						<OverUnderSummaryChart 
							budgetData={budgetData}
							isModal={false}
							selectedSnapshots={selectedSnapshots}
							isLoadingSnapshots={isLoadingSnapshots}
						/>
						</CardContent>
					</Card>
				</Grid>
				<Grid item xs={12} style={{display: 'none'}}>
					<Card className="card-content-container">
						<CardContent className="card-content">
							<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginLeft: "20px", marginRight: "20px" }}>
								<Typography variant="subtitle1" className="card-title" style={{ textAlign: 'left', verticalAlign: 'top' }}>Progress Tracker (Work in Progress)</Typography>
								<TablePopoutButton 
									budgetData={budgetData}
									tableComponent={ProgressTrackerTable}
									title={'Progress Tracker'}
								/>
							</div>
							<ProgressTrackerTable style={{maxHeight: '150px'}}
								isModal={false}
							/>
						</CardContent>
					</Card>
				</Grid>
				</Grid>
			</Grid>
		</Grid>
	);

	return (
		<Container style={{ marginTop: '15px'}}>
			<Grid item xs={12}>
				<div className="header-container">
					<HeaderComponent
						projectData={projectData}
						selectedSnapshots={selectedSnapshots}
						setSelectedSnapshots={setSelectedSnapshots}
						selectedProjectId={selectedProjectId}
						initialSnapshots={initialSnapshots}
						setTeamData={setTeamData}
						setBudgetData={setBudgetData}
						setProjectData={setProjectData}
					/>
				</div>

				{/* Conditionally Render Main Content */}
				<div style={{ marginTop: '15px' }}>
					{renderProjectView()}
				</div>
			</Grid>

			{/* Access Dialog */}
			<Dialog open={noAccessDialogOpen} onClose={handleCloseNoAccessDialog}>
				<DialogTitle id="no-access-dialog-title">Access Issue</DialogTitle>
				<DialogContent>
					<DialogContentText>
						{accessPossible === false 
							? "Access to this project is not possible due to your current permissions." 
							: "You do not have permission to view this project, but access is possible. Please make sure you are added to the Project Roles in the Procore project and then reload this app."}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleCloseNoAccessDialog} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
};

export default ProjectDashboard;