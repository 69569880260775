import { createSlice } from '@reduxjs/toolkit';
const HUB_API_DOMAIN_NAME = process.env.REACT_APP_HUB_API_DOMAIN_NAME;


// Define Values for Initial State (empty & null values)
const initialState = {
  csrfToken: null,
  authenticated: false,
  tab: 'project',
  projectId: null,
  hubDomain: null,
  apiKey: null,
  apiHeader: null,
  isLoading: false,
};

// Create Project Slice
const globalSlice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // Setter for csrfToken
    setCsrfToken(state, { payload }) {
      state.csrfToken = payload;
    },
    setAuthenticated(state, { payload }) {
      state.authenticated = payload;
    },
    setTab(state, { payload }) {
      state.tab = payload;
    },
    setProjectId(state, { payload }) {
      state.projectId = payload;
    }, 
    setHubDomain(state, { payload }) {      
      state.hubDomain = payload;
    },
    setApiKey(state, { payload }) {
      
      state.apiKey = payload;
    },
    setApiHeader(state, { payload }) {
      state.apiHeader = payload;
    },
    setIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    // Reset all state to initial values
    resetGlobalState() {
      return initialState;
    },
  },
});

// Export the actions (setters) to be used in our dashboard
export const {
  setCsrfToken,
  setAuthenticated,
  setTab,
  setProjectId,
  setHubDomain,
  setApiHeader,
  setApiKey,
  setIsLoading,
  hubDomain,
} = globalSlice.actions;

// Export the reducer for the Redux store
export default globalSlice.reducer;

