import React from "react";
import { Typography } from "@material-ui/core";

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const ApprovedChangeEvents = ({ budgetData }) => {

    const changeEventAmount = () => {
        if (budgetData && budgetData?.current_contract_summary?.approved_pcos_pccos && budgetData?.current_contract_summary?.approved_pcos_pccos !== undefined) {
            const changeEvents = budgetData?.current_contract_summary?.approved_pcos_pccos
            const roundedCEs = Math.ceil(changeEvents)
            return `$${roundedCEs.toLocaleString()}`;
        }
        return '--';
    };

    return (
        <div>
            <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
                {changeEventAmount()}
            </Typography>
        </div>
    );
}

export default ApprovedChangeEvents;