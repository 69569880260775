import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { withStyles } from "@material-ui/core/styles";
import {
    formatDollar,
    formatPercentage,
    colorCodeRed,
  } from "../utilityFunctions";
import { CardContent, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";

const useStyles = makeStyles({
    table: {
      minWidth: 500,
      whiteSpace: "nowrap",
    },
    tableContainer: {
      overflowX: "auto",
    },
    statusIcon: {
      verticalAlign: "middle",
      marginRight: "8px",
    },
    red: {
      color: "red",
    },
    green: {
      color: "green",
    },
    copyButton: {
      margin: "10px 0",
    },
    excelButton: {
      backgroundColor: "#1e613b",
      color: "white",
      margin: "10px 0px 10px 10px",
      "&:hover": {
        backgroundColor: "#1e613b",
      },
    },
  });
  
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: "#093d61",
      color: theme.palette.common.white,
      fontSize: 14,
      paddingTop: 5,
      paddingBottom: 5,
      whiteSpace: "nowrap",
      width: "16.66%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    body: {
      fontSize: 12,
      paddingTop: 5,
      paddingBottom: 5,
      whiteSpace: "nowrap",
      width: "16.66%",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles({
    root: {
      "&:nth-of-type(even)": {
        backgroundColor: "#f0f0f0",
      },
    },
  })(TableRow);
  
  // Row styles for the Shared Savings Table Specifically 
  const rowStyles = {
    subtotal: { 
      fontWeight: "bold", 
    },
    projected_final_contract_value_without_savings: {
      fontWeight: "bold",
      borderTop: "thick double gray",
    },
    revenue_back_to_owner: { 
      fontWeight: "bold", 
    },
    savings_added_to_profit: { 
      fontWeight: "bold", 
    },
    total_revised_budget_after_revenue_back_to_owner: {
      borderTop: "thick double gray",
      fontWeight: "bold",
    },
    total_gross_profit_after_savings_split: {
      fontWeight: "bold",
    },
  };
  
  // Adding Shared Savings Table as additional render -- Break out into separate component if needed
  const SharedSavingsTable = ({budgetData, classes}) => {
    if (!budgetData || !budgetData?.shared_savings_data?.shared_savings) {
      return null; // Don't render the table if `shared_savings` is false or missing
    }
  
    const orderedKeys = [
      "general_conditions_budget",
      "estimated_gmp_costs",
      "subtotal",
      "insurance",
      "fee",
      "projected_final_contract_value_without_savings",
      "current_owner_contract_value",
      "projected_savings",
      "current_projected_gmp_settings",
      "savings_added_to_gross_profit",
      "revenue_back_to_owner",
      "total_revised_budget_after_revenue_back_to_owner",
      "total_gross_profit_after_savings_split",
      "ls_profit",
      "savings_added_to_profit",
    ];
  
    const keyLabels = {
      general_conditions_budget: "General Conditions Budget (Revenue)",
      estimated_gmp_costs: "Estimated GMP Costs",
      subtotal: "Subtotal",
      insurance: "Negotiated Insurance",
      fee: " Negotiated Fee",
      projected_final_contract_value_without_savings:
        "Projected Final Contract Value (Without Savings)",
      current_owner_contract_value: "Current Owner Contract Value",
      projected_savings: "Projected Savings",
      current_projected_gmp_settings: "Current Projected GMP Savings",
      savings_added_to_gross_profit: "Savings Added to Gross Profit",
      revenue_back_to_owner: "Revenue Back to Owner",
      total_revised_budget_after_revenue_back_to_owner: "Total Revised Budget After Revenue Back to Owner",
      total_gross_profit_after_savings_split:
      "Total Gross Profit After Savings Split",
      ls_profit: "LS Profit",
      savings_added_to_profit: "Savings Added to Profit",
     
    };
  
    return (
      <div style={{ marginTop: "20px" }}>
        <Typography variant="h6" className="bvt-table-title">
          Shared Savings Details
        </Typography>
        <CardContent>
        <TableContainer component={Paper}>
          <Table className={classes.table} aria-label="shared-savings-table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Value</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
            {orderedKeys.map((key) => {
                const value = budgetData?.shared_savings_data[key];
  
                // Only render rows for keys that exist in the data
                if (value === undefined) return null;
  
                return (
                  <StyledTableRow 
                  key={key} 
                  className={
                    key === "subtotal" ? "bvt-table-total" :
                    key === "total_revised_budget_after_revenue_back_to_owner" ? "bvt-table-total" :
                    key === "total_gross_profit_after_savings_split" ? "bvt-table-total-b" :
                    ""
                  }
                  style={rowStyles[key] || {}}>
                    <StyledTableCell>{keyLabels[key] || key}</StyledTableCell>
                    <StyledTableCell style={{
                        color: typeof value === "number" && value < 0 ? "red" : "inherit", ...rowStyles[key],
                      }}>
                      {typeof value === "number"
                        ? formatDollar(value) 
                        : value.toString()} 
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        </CardContent>
      </div>
    );
  };

  export default SharedSavingsTable;