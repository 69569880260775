import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-hot-toast';

import {
	Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, MenuItem, Select, FormControl, InputLabel, Modal, Box, Button, TextField
} from '@material-ui/core';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addOverUnderNote } from '../../Containers/api';

import { withStyles, makeStyles } from '@material-ui/core/styles';
import { set } from 'lodash';
import { toastStyle } from '../../Containers/continerUtilityFunctions';
import { formatDollar } from '../utilityFunctions'; // Adjust the import path as needed


const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#093d61',
		color: theme.palette.common.white,
		fontSize: 14,
		paddingTop: 7,
		paddingBottom: 7,
		whiteSpace: 'nowrap',
		width: '16.66%',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	body: {
		fontSize: 12,
		paddingTop: 5,
		paddingBottom: 5,
		whiteSpace: 'nowrap',
		width: '16.66%',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		customFont: {
			fontFamily: "Titillium Web, sans-serif",
	},
	},
}))(TableCell);


const useReduxSelectors = () => {
	const projectId = useSelector((state) => state.project.selectedProjectId);
	return { projectId };
};


const StyledTableRow = withStyles({
	root: {
		'&:nth-of-type(even)': {
				backgroundColor: '#f0f0f0', 
		},
	},
})(TableRow);

const OverUnderTable = ({ title, data, maxHeight, classes, showNotes=false, addNotes=false,updateDataNotes }) => {
	const { projectId  } = useReduxSelectors();
	
	const [notesModalShow, setNotesModalShow] = useState(false);
	const [notesModalText, setNotesModalText] = useState('');
	const [notesModalNotes, setNotesModalNotes] = useState({});
	const [notesModalCostCode, setNotesModalCostCode] = useState();

	
	if (data?.length === 0) return (<></>);

	let handleClickOnAddNotes = (row) => {
		setNotesModalShow(true);
		setNotesModalNotes(row.notes);
		setNotesModalCostCode(row.cost_code);
	}

	let handleNotesModalButtonCancel = () => {
		setNotesModalShow(false);
		setNotesModalText('');
		setNotesModalNotes({});
		setNotesModalCostCode();
	}

	let handleNotesModalButtonSave = () => {
		// Validate that notesModalText is a non-empty string
		if (typeof notesModalText === 'string' && notesModalText.trim() !== '') {
			const addOverUnderNoteAsync = async () => {
				try {
					const res = await addOverUnderNote(projectId, notesModalCostCode, notesModalText);
					if (res) {
						toast.success("Note Saved! Refresh table to view.", toastStyle());
						updateDataNotes(notesModalCostCode, res?.notes);
					} else {
						console.error('Error saving the note:', res);
						toast.error("Error saving the note", toastStyle());
					}
				} catch (error) {
					console.error('Error saving the note:', error);
					toast.error("Error saving the note", toastStyle());
				}
			};
			addOverUnderNoteAsync();
			setNotesModalShow(false);
			setNotesModalText('');
			setNotesModalNotes({});
		} else {
			// Show an error message or handle invalid input
			console.error('Invalid note. Please enter a valid note.');
			toast.error("Invalid note. Please enter a valid note.", toastStyle());
		}		
	}

	const getDisplayNotes = (row) => {
		const notes = row.notes;
		if (notes && typeof notes === "object" && !Array.isArray(notes)) {
			const keys = Object.keys(notes);
			if (keys.length && keys.length > 0) {
				let date = keys[keys.length - 1].split(' ')[0];

				let note = notes[keys[keys.length - 1]];
				if (note.length > 50 || keys.length > 1) {
					note = date + ': '+ note.substring(0, 50) + '...';
				}
				return note
			} 
		}
		return '';
	};

	return (
		<>
			<Typography variant="h6" className={classes.tableTitle} style={{ marginBottom: '10px', marginTop: title === 'Top Losses' ? '30px' : '0' }}>
				{title}
			</Typography>
			<TableContainer component={Paper} style={{ maxHeight }}>
				<Table className={classes.table} aria-label={`${title} table`}>
					<TableHead>
						<StyledTableRow>
							<StyledTableCell>Value</StyledTableCell>
							<StyledTableCell>Description</StyledTableCell>
							{showNotes && <StyledTableCell>Notes</StyledTableCell>}
							{addNotes && <StyledTableCell>Show All Notes / Add Notes</StyledTableCell>}
						</StyledTableRow>
					</TableHead>
					<TableBody>
						{data?.map((row, index) => (
							<StyledTableRow key={index}>
								<StyledTableCell component="th" scope="row">
									{formatDollar(row.value)}
								</StyledTableCell>
								<StyledTableCell>{row.description}</StyledTableCell>
								{showNotes && <StyledTableCell>{getDisplayNotes(row)}</StyledTableCell>}
								{addNotes && (<StyledTableCell><AddCircleOutlineIcon style={{color:'green'}} onClick={() => handleClickOnAddNotes(row)}/></StyledTableCell>)}
							</StyledTableRow>
						))}
					</TableBody>
				</Table>
			</TableContainer>
			{addNotes && (
				<Modal
					open={notesModalShow}
					onClose={handleNotesModalButtonCancel}
					aria-labelledby="simple-modal-title"
					aria-describedby="simple-modal-description"
				>
					<Box
					sx={{
						position: 'absolute',
						top: '50%',
						left: '50%',
						transform: 'translate(-50%, -50%)',
						width: '50vw',
						bgcolor: 'background.paper',
						boxShadow: 24,
						p: 4,
						borderRadius: 2,
					}}
					>
					<Typography variant="h6" id="simple-modal-title" mb={2}>Add Notes</Typography>
					{
						notesModalNotes && (   
							<>                       
							<br />
							<Typography variant="body2" id="simple-modal-description" mb={2}>
								Existing Notes: 
								<br />
								{Object.keys(notesModalNotes).map((key) => (
									<div key={key} style={{wordWrap: 'break-word', overflowWrap: 'break-word', whiteSpace: 'normal' }}>
										<b>{key?.split(' ')[0]}</b>: {notesModalNotes[key]}
									</div>
								))}
							</Typography>
							<br />
							</>  
						)
					}
					<TextField
						fullWidth
						size="small"
						variant="outlined"
						value={notesModalText}
						onChange={(e) => setNotesModalText(e.target.value)}
						placeholder="Type here..."
						margin="dense"
					/>
						<Box mt={2} display="flex" justifyContent="space-between">
							<Button variant="contained" color="primary" onClick={() => handleNotesModalButtonSave()}>Save</Button>
							<Button variant="outlined" color="secondary" onClick={() => handleNotesModalButtonCancel()}>Cancel</Button>
						</Box>
					</Box>
				</Modal>
			)}
		</>
	);
};

export default OverUnderTable;