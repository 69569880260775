import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { HUB_URL } from '../../utils/config/config';
import ProjectDashboard from '../../components/project_dashboard/Components/project_dashboard';
import PortfolioView from '../../components/project_dashboard/Containers/portfolio_view';
import { getHeaders } from '../../components/project_dashboard/Containers/continerUtilityFunctions';
import { authCheck } from '../../components/project_dashboard/Containers/api';
import { setCsrfToken, setAuthenticated, setHubDomain, setTab, setApiHeader, setApiKey } from '../../store/slices/globalSlice';
import { setSelectedProjectId } from '../../store/slices/projectSlice';
import { Hub } from '@mui/icons-material';

const API_AUTH_HEADER = process.env.REACT_APP_API_AUTH_HEADER;
const API_KEY = process.env.REACT_APP_API_KEY;

const useReduxSelectors = () => {
	const csrfToken = useSelector((state) => state.global.csrfToken);
	const authenticated = useSelector((state) => state.global.authenticated);
	const tab = useSelector((state) => state.global.tab);
	const hubDomain = useSelector((state) => state.global.hubDomain);
	const isLoading = useSelector((state) => state.global.isLoading);
	const selectedProjectId = useSelector((state) => state.project.selectedProjectId);

	return { csrfToken, authenticated, tab, hubDomain, isLoading, selectedProjectId };
};

const PHD = () => {
	const dispatch = useDispatch();
	const { csrfToken, authenticated, tab, hubDomain, selectedProjectId } = useReduxSelectors();

	useEffect(() => {
		dispatch(setApiHeader(API_AUTH_HEADER));
		dispatch(setApiKey(API_KEY));
	}, [dispatch]);
	
	const [projects, setProjects] = useState([]);
		const [companies, setCompanies] = useState([]);
		const [portfolioView, setPortfolioView] = useState(false);
		const [selectedCompany, setSelectedCompany] = useState(null);
		const [isLoading, setIsLoading] = useState(false);

		// Portfolio View Filters
		const [divisionOptions, setDivisionOptions] = useState([]);
		const [selectedDivisions, setSelectedDivisions] = useState([]);

		const [stageOptions, setStageOptions] = useState([]);
		const [selectedStages, setSelectedStages] = useState([]);

		const [opsLeaderOptions, setOpsLeaderOptions] = useState([]);
		const [selectedOpsLeaders, setSelectedOpsLeaders] = useState([]);

		const [projectTypeOptions, setProjectTypeOptions] = useState([]);
		const [selectedProjectTypes, setSelectedProjectTypes] = useState([]);
		
		const [accountOptions, setAccountOptions] = useState([]);
		const [selectedAccounts, setSelectedAccounts] = useState([]);

		const [portfolioCompanies, setPortfolioCompanies] = useState([]);
		const [selectedPortfolioCompany, setSelectedPortfolioCompany] = useState([]);

		// Portfolio View Sorting Options
		const [sortField, setSortField] = useState('project_name');
		const [pageNumber, setPageNumber] = useState(1);
		const [totalResults, setTotalResults] = useState(0);
		const [hasMoreResults, setHasMoreResults] = useState(false);
		const resultsPerPage = 25;
		const [portfolioError, setPortfolioError] = useState(null);
		const [prevPageExists, setPrevPageExists] = useState(false);
		const [nextPageExists, setNextPageExists]= useState(false);

		// UseEffect to call Portfolio Search when any of the dropdowns are updated
		useEffect(() => {
				if (portfolioView) {
					// Check if filters were updated
					const filtersSelected = 
						selectedPortfolioCompany || 
						(selectedDivisions && selectedDivisions.length > 0) || 
						(selectedStages && selectedStages.length > 0) || 
						(selectedOpsLeaders && selectedOpsLeaders.length > 0) || 
						(selectedProjectTypes && selectedProjectTypes.length > 0) || 
						(selectedAccounts && selectedAccounts.length > 0);

					if (filtersSelected && pageNumber !== 1) {
							// Reset to page 1 when any filter is selected
							setPageNumber(1);
					} else {
							// Clear projects and set loading state for any other trigger
							setProjects([]);
							setIsLoading(true);
							handlePortfolioSearch();
					}
				}
			}, [
				portfolioView,
				selectedPortfolioCompany,
				selectedDivisions,
				selectedStages,
				selectedOpsLeaders,
				selectedProjectTypes,
				selectedAccounts,
				sortField
			]);

			// Breaking out this useEffect to handle pageNumber changes
			useEffect(() => {
				if (portfolioView) {
					setIsLoading(true);
					handlePortfolioSearch();
				}
			}, [pageNumber]);

		// Send message to parent to establish trust
		useEffect(() => {
				const urlParams = new URLSearchParams(window.location.search);
				const domain_from_hub = urlParams.get('domain');
				let parent_url = `${domain_from_hub.includes('arcomurray') || domain_from_hub.includes('arco1') ? 'https' : 'http'}://${domain_from_hub}`;
				if ([HUB_URL, 'https://arcohub.arco1.com', ].includes(parent_url)) {
					dispatch(setHubDomain(parent_url));
					let data = {
						origin: window.location.origin,
						type: 'first_hand_shake'
					}
					window.parent.postMessage(data, parent_url);
				}
		}, []);

		useEffect(() => {
			// Event Listener for tab and project changes in URL
			const messageListener = (event) => {
				if (['https://arcohub.arco1.com',HUB_URL].includes(event.origin)) {
					const localTab = event.data.tab === 'None' ? 'project' : event.data.tab;
					let localProject = null
					try {
						localProject = parseInt(event.data.project_id);
					} catch (error) {
						localProject = null;
					}
					dispatch(setTab(localTab));
					dispatch(setSelectedProjectId(localProject));
					dispatch(setHubDomain(event.data.current_domain));
					dispatch(setCsrfToken(event.data.token));
				}
			};


			window.addEventListener("message", messageListener);

			return () => {
				window.removeEventListener("message", messageListener);
			};
		}, [dispatch]);

		// Send message to parent for tab and project change
		useEffect(() => {
			if (hubDomain) {
				const data = {
					type: 'params_change',
					origin: window.location.origin,
					tab: tab,
					project_id: selectedProjectId,
				};
				window.parent.postMessage(data, hubDomain);
			}		
		}, [tab,selectedProjectId,hubDomain]);

		useEffect(() => {
			if (csrfToken) {
				const urlParams = new URLSearchParams(window.location.search);
				const userUid = urlParams.get('user_uid');

				const headers = getHeaders(csrfToken, API_AUTH_HEADER, API_KEY);

				const authCheckAsync = async () => {
					try {
						const authData = await authCheck(userUid, csrfToken, headers, hubDomain);
						if (authData?.auth_check_passed) {
							dispatch(setAuthenticated(true));
						} else {
							console.error('Auth Check #1 Failed', authData);
						}
					} catch (error) {
						console.error('Error during auth check:', error);
					}
				};

				authCheckAsync();
			}
		}, [csrfToken, dispatch, hubDomain]);
		

		// ==================================================
		// ========= Fetching for Portfolio View ============
		// ==================================================
		const handlePortfolioSearch = () => {
			const urlParams = new URLSearchParams(window.location.search);
			const user_uid = urlParams.get('user_uid');
		
			// Make sure we have a user_uid and we're in portfolioView mode
			if (user_uid && portfolioView) {
				const headers = new Headers();
				headers.append('Content-Type', 'application/json');
				headers.set('X-CSRFToken', csrfToken);
				headers.append('Auth', API_AUTH_HEADER);
				headers.append('api-key', API_KEY);
		
				// Clear projects list and set loading state
				setPortfolioError(null);
				setIsLoading(true);
				setProjects([]);
		
				// First, perform an auth check
				fetch(`${hubDomain}/phd/auth_check/`, {
					method: 'POST',
					headers: headers,
					body: JSON.stringify({
						user_uid: user_uid,
						csrf_token: localStorage.getItem('hub_csrf_token'),
					}),
					credentials: 'include',
				})
					.then((response) => {
						// If non-200 response, set an error message for FE to break isLoading
						if (!response.ok) {
							setPortfolioError(`An error occurred while fetching data: Status ${response.status}. Our dev team is on it!`);
							setIsLoading(false);
							return null; 
						}
						return response.json();
					})
					.then((authData) => {
						if (authData.auth_check_passed) {
							dispatch(setAuthenticated(true));
		
							// Build body for get_pv_data
							const body = {
								csrf_token: localStorage.getItem('hub_csrf_token'),
								user_uid: user_uid,
								sorting_order: sortField, // Using the sortField - project_name by default
								page_number: pageNumber,
								company_filter: selectedPortfolioCompany?.length > 0 ? selectedPortfolioCompany : null,
								division_filter: selectedDivisions?.length > 0 ? selectedDivisions : null,
								stage_filter: selectedStages?.length > 0 ? selectedStages : null,
								ops_leader_filter: selectedOpsLeaders?.length > 0 ? selectedOpsLeaders : null,
								project_type_filter: selectedProjectTypes?.length > 0 ? selectedProjectTypes : null,
								account_filter: selectedAccounts?.length > 0 ? selectedAccounts : null,
							};
							// call get_pv_data for proj list
							fetch(`${hubDomain}/phd/get_pv_data/`, {
								method: 'POST',
								headers: headers,
								body: JSON.stringify(body),
								credentials: 'include',
							})
							.then((response) => {
								// If non-200 response, set an error message for FE to break isLoading
								if (!response.ok) {
									setPortfolioError(`Error fetching data: Status ${response.status}). Our dev team is on it!`);
									setIsLoading(false);
									return null; 
								}
								return response.json();
							})
								.then((data) => {
									if (data) {
										setProjects(data.projects || []);
										// setPortfolioCompanies(data.companies || []);
										setTotalResults(data.total_results || 0);
										setHasMoreResults(data.more_results || false);
										setPrevPageExists(data.prev_page_exists || false);
										setNextPageExists(data.next_page_exists || false);
										// Fill out dropdowns
										if (data.filter_dropdowns) {
											setPortfolioCompanies(data.filter_dropdowns.companies || [])
											setDivisionOptions(data.filter_dropdowns.divisions || []);
											setStageOptions(data.filter_dropdowns.stages || []);
											setOpsLeaderOptions(data.filter_dropdowns.ops_leaders || []);
											setProjectTypeOptions(data.filter_dropdowns.project_types || []);
											setAccountOptions(data.filter_dropdowns.accounts || []);
										}
									} else {
										console.error('Failed setting Projects data:', data);
									}
									setIsLoading(false);
								})
								.catch((error) => {
									console.error('Error in fetching PV data:', error);
									setIsLoading(false);
								});
						} else {
							console.error('Token validation failed:', authData);
							setIsLoading(false);
						}
					})
					.catch((error) => {
						console.error('Error during auth check:', error);
						setIsLoading(false);
					});
			} else {
				console.log('Portfolio search triggered but user_uid is not available.');
			}
		};
		

	if (!authenticated) {
		return <div>Loading...</div>;
	}

	return (
		<div>
			{tab === 'project' ? (
				<ProjectDashboard 
				/>
			) : tab === 'portfolio' ? (
				<PortfolioView />
			) : null}
		</div>
	);
};

export default PHD;