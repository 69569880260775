import React from "react";
import { Typography } from "@material-ui/core";
import { formatDateShort } from "../utilityFunctions";

const ContractSignedDate = ({ projectData }) => {
    const procoreStartDate = projectData?.project_data?.start_date || projectData?.start_date;

return (
    <div>
        {projectData ? (
            <Typography style={{ textAlign:'center'}} className="main-key-project-info-body" variant="body1">
                {formatDateShort(procoreStartDate) || '--'}
            </Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default ContractSignedDate;