import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import SyncIcon from "@mui/icons-material/Sync";
import { toast } from "react-hot-toast";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Button,
  Typography,
  Paper,
  Checkbox,
} from "@mui/material";
import {
  projectSettingsDataPanelAPIs,
  saveProjectSettings,
  triggerPHDSyncWithSalesforce
} from "../../Containers/api";
import { fontFamily } from "@mui/system";

const useReduxSelectors = () => {
  const selectedProjectId = useSelector(
    (state) => state.project.selectedProjectId
  );
  return { selectedProjectId };
};

const defaultSyncData = [
  {
    id: 1,
    name: "Procore Team Sync",
    description: "Synchronize project team details from Procore.",
    lastSync: "Ready to Sync",
    lastSyncColor: "black",
  },
  {
    id: 2,
    name: "Budget Data Sync",
    description:
      "Synchronize budget data for this project from Procore.",
    lastSync: "Ready to Sync",
    lastSyncColor: "black",
  },
  {
    id: 3,
    name: "Project Info Sync",
    description: "Update project metadata and statuses.",
    lastSync: "Ready to Sync",
    lastSyncColor: "black",
  },
];

const DataControlPanel = ({
  setTeamData,
  setBudgetData,
  setProjectData,
  bvtFields,
  setBvtFields,
}) => {
  const { selectedProjectId } = useReduxSelectors();

  const getInitialSyncData = () => {
    if (!selectedProjectId) return [];
    const storedData = localStorage.getItem(`syncData_${selectedProjectId}`);
    return storedData ? JSON.parse(storedData) : defaultSyncData;
  };
  const [loadingId, setLoadingId] = useState(null);
  const [syncData, setSyncData] = useState(getInitialSyncData);

  useEffect(() => {
    if (selectedProjectId) {
      localStorage.setItem(
        `syncData_${selectedProjectId}`,
        JSON.stringify(syncData)
      );
    }
  }, [syncData, selectedProjectId]);

  const handleForceSync = async (id) => {
    setLoadingId(id);
    const now = new Date().toLocaleString();
    const url = getSyncUrl(id);

    try {
      const data = await projectSettingsDataPanelAPIs(selectedProjectId, url);

      if (data.error) {
        throw new Error(data.error);
      }

      updateSyncData(id, data, now);
      updateParentState(id, data);

      toast.success(
        `${
          syncData.find((row) => row.id === id).name
        } successfully synced! Refresh to view changes.`
      );
    } catch (error) {
      toast.error(
        `Failed to sync ${syncData.find((row) => row.id === id).name}: ${
          error.message
        }`
      );
      updateSyncData(id, null, now);
    } finally {
      setLoadingId(null);
    }
  };

  const getSyncUrl = (id) => {
    switch (id) {
      case 1:
        return `/phd/refresh_project_team/`;
      case 2:
        return `/phd/refresh_project_budget_data/`;
      case 3:
        return `/phd/get_project_data/`;
      default:
        throw new Error("Unknown Sync ID");
    }
  };

  const updateSyncData = (id, data, now) => {
    setSyncData((prevData) =>
      prevData.map((item) =>
        item.id === id
          ? {
              ...item,
              lastSync: data
                ? `Last synced at ${now}`
                : `Failed to sync at ${now}`,
              lastSyncColor: data ? "green" : "red",
            }
          : item
      )
    );
  };

  const updateParentState = (id, data) => {
    switch (id) {
      case 1:
        setTeamData(data);
        break;
      case 2:
        setBudgetData(data);
        break;
      case 3:
        setProjectData(data);
        break;
      default:
        console.error("Unknown sync ID:", id);
    }
  };

  const handleTriggerSalesforceSync = async () => {
    toast("This feature is currently in development.", { icon: "⏳", fontFamily: 'Titillium Web, sans-serif' });
    // 
    // try {
    //   const response = await triggerPHDSyncWithSalesforce(selectedProjectId);

    //   if (response.success) {
    //     toast.success("Salesforce sync triggered successfully!");
    //   } else {
    //     toast.error(response.error || "Error triggering Salesforce sync.");
    //   }
    // } catch (error) {
    //   toast.error(`Error triggering Salesforce sync: ${error.message}`);
    // }
  };

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
        <Typography variant="h6" className="titillium-web" gutterBottom>
          Data Control Panel
        </Typography>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "1px",
          alignItems: "center",
        }}
      >
        <Typography>PHD Sync</Typography>
        <Checkbox
          checked={bvtFields.phd_sync}
          onChange={(e) =>
            setBvtFields({ ...bvtFields, phd_sync: e.target.checked })
          }
        />
         <Button
          className="outlined-primary-button"
          endIcon={<SyncIcon />}
          onClick={handleTriggerSalesforceSync}
          style={{maxHeight: '30px'}}
        >
          Trigger Sync to Salesforce
        </Button>
        </div>
        <Typography style={{color: 'darkgray'}}>Check this box to sync "Revised Contract Amount" and "Cost Status Profit" from Procore's latest Budget Snapshot to Salesforce and to Viewpoint. Keep it checked unless your project has special conditions (e.g., not using the Procore Budget). Questions? Contact: <a style={{textDecoration: 'none', color: 'inherit'}} href="mailto:innovation@arcomurray.com?subject=PHD Feedback">innovation@arcomurray.com</a></Typography>
      <br />
      <Typography>Manually Force Sync</Typography>
      <Typography style={{color: 'darkgray'}}>Manually forcing a sync should be rare and used mainly for troubleshooting. If you find yourself doing it often, contact: <a style={{textDecoration: 'none', color: 'inherit'}} href="mailto:innovation@arcomurray.com?subject=PHD Feedback">innovation@arcomurray.com</a></Typography>
      <TableContainer component={Paper} sx={{ marginTop: 1 }}>
        <Table>
          <TableHead style={{ backgroundColor: "#45b6e6" }}>
            <TableRow>
              <TableCell className="data-control-headers">SYNC NAME</TableCell>
              <TableCell className="data-control-headers">
                DESCRIPTION
              </TableCell>
              <TableCell className="data-control-headers" align="center">
                FORCE SYNC
              </TableCell>
              <TableCell className="data-control-headers" align="center">
                LAST SYNC
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {syncData.map((row) => (
              <TableRow key={row.id}>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.description}</TableCell>
                <TableCell align="center">
                  <Button
                    className="outlined-primary-button"
                    onClick={() => handleForceSync(row.id)}
                    disabled={loadingId === row.id}
                    endIcon={<SyncIcon />}
                  >
                    {loadingId === row.id ? "Syncing..." : "Sync Now"}
                  </Button>
                </TableCell>
                <TableCell align="center">
                  <Typography
                    style={{
                      color: row.lastSyncColor || "black",
                      fontSize: ".85rem",
                    }}
                  >
                    {row.lastSync}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default DataControlPanel;