import React, { useState } from 'react';
import { IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Link, Typography } from '@mui/material';
import IconInfo from '@mui/icons-material/Info';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const InfoDialogButton = ({ sourceProcore, projectData, source }) => {
    const [openDialog, setOpenDialog] = useState(false);

    const handleClickOpen = () => {
        // Confirm projectData is not empty
        if (projectData && Object.keys(projectData).length !== 0) {
            setOpenDialog(true);
        }
    };

    const handleClose = () => {
        setOpenDialog(false);
    };

    // Modular Mapping through switch cases for project information pop outs.
    let sourceText;
    let projectId;
    let modalConfigURL;
    let helpText;
    let linkName;
    let buttonClass;
    let helpText2;
    switch (source) {
        case 'procore-team':
            sourceText = 'Project Team information is sourced from Procore.';
            projectId = projectData?.project_data?.procore_id;
            helpText = "You can update your Team's information through the project directory linked below.";
            modalConfigURL = `https://app.procore.com/${projectId}/project/directory/configure_tab`;
            linkName = "Open Procore Directory Settings";
            buttonClass='smaller-procore-button';
            break;
        case 'salesforce':
            sourceText = 'This information is sourced from Salesforce.';
            helpText = "This tile pulls data from Salesforce (SFDC). Update this project opportunity via the link below if you have a Salesforce license, or reach out to your ops leader.";
            modalConfigURL = `https://arcomurray.lightning.force.com/lightning/r/Opportunity/${projectData?.project_data?.sfdc_id}/view`
            linkName = "Open Opportunity in Salesforce";
            buttonClass='smaller-primary-button';
            break;
        case 'project-info-summary':
            sourceText = "This information is sourced from Procore's admin panel.";
            helpText = "You can update your project's information through the admin panel linked below.";
            projectId = projectData?.project_data?.procore_id;
            modalConfigURL = `https://app.procore.com/${projectId}/project/admin`;
            linkName = "Open Procore Admin Tool";
            buttonClass='smaller-procore-button';
            break;
        case 'contract-financial-summary':
            sourceText = "This information is pulled from Procore budget snapshots.";
            helpText = "You can adjust the selected snapshots under the Project Settings in the upper right corner of this page. Or click the link below to update your budget information in Procore.";
            projectId = projectData?.project_data?.procore_id;
            modalConfigURL = `https://app.procore.com/projects/${projectId}/budgeting`;
            linkName = "Open Procore Budget";
            buttonClass='smaller-procore-button';
            break;
        case 'contract-structure':
            sourceText = "This information is pulled from budget snapshots and cost code classification in the AMI Budget Variance Settings.";
            helpText = "Please use the latest version of AMI Budget Variance Settings to automatically sync data with Project Health Dashboard. You can adjust the selected snapshots in the upper right of the header. Or click the link below to update your budget information in Procore.";
            projectId = projectData?.project_data?.procore_id;
            modalConfigURL = `https://app.procore.com/projects/${projectId}/budgeting`;
            linkName = "Open Procore Budget";
            buttonClass='smaller-procore-button';
            break;
        case 'contingency-chart':
            sourceText = "This contingency data is sourced from your project's budget snapshots.";
            helpText = "If there are any snapshots you would like to adjust. Please navigate to Project Settings in the righthand side of the upper banner. Otherwise, you can make adjustments in Procore and they will be reflected in this dashboard.";
            modalConfigURL = `https://app.procore.com/projects/${projectId}/budgeting`;
            linkName = "Open Procore Budget";
            buttonClass='smaller-procore-button';
            break;
        case 'profit-chart':
            sourceText = "This profit data is sourced from your project's budget snapshots.";
            helpText = "If there are any snapshots you would like to adjust. Please navigate to Project Settings in the righthand side of the upper banner. Otherwise, you can make adjustments in Procore and they will be reflected in this dashboard.";
            modalConfigURL = `https://app.procore.com/projects/${projectId}/budgeting`;
            linkName = "Open Procore Budget";
            buttonClass='smaller-procore-button';
            break;
        case 'costs-and-billings':
            sourceText = "Data for Costs and Billings is sourced from Viewpoint.";
            helpText = "Viewpoint data will be refreshed 3 days after the latest billing. We are working with ABS to reduce this data delay from Vista. If anything looks off, please notify the AMI team.";
            modalConfigURL = `mailto:innovation@arcomurray.com?subject=PHD Feedback`;
            linkName = "Contact Us";
            buttonClass='smaller-primary-button';
            break;
        case 'percent-complete':
            sourceText = "This data is sourced from Procore's Project Information settings.";
            helpText = "You can update your project's key dates in the Project Information section of your job's Procore Admin Tool.";
            helpText2 = `
                    <ul>
                        <li><strong>Start Date:</strong> Contract Date it was signed.</li>
                        <li><strong>Actual Start Date:</strong> Groundbreaking Date.</li>
                        <li><strong>Completion Date:</strong> Substantial Completion Date.</li>
                        <li><strong>Projected Finish Date:</strong> End of Warranty Date.</li>
                    </ul>
                `;
            projectId = projectData?.project_data?.procore_id;
            modalConfigURL = `https://app.procore.com/${projectId}/project/admin`;
            linkName = "Adjust in Procore";
            buttonClass='smaller-procore-button';
            break;
        case 'project-location':
            sourceText = "Location data is sourced from Salesforce."
            helpText = "Get in touch with a licensed Salesforce User from your team if this location looks incorrect.";
            break;
        default:
            sourceText = ''; 
            break;
    }
    return (
        <>
            <IconButton onClick={handleClickOpen} style={{ textAlign: 'right' }}>
                <IconInfo />
            </IconButton>
            <Dialog open={openDialog} onClose={handleClose}>
                <DialogTitle>{sourceText}</DialogTitle>
                <DialogContent>
                    <DialogContentText component="div">
                        <Typography>{helpText}</Typography>
                        { helpText2 && (
                            <>
                            <div style={{display: 'flex', flexDirection: 'column'}}>
                            <p style={{marginBottom: '-10px'}}>Procore Date Definitions:</p>
                            <div dangerouslySetInnerHTML={{ __html: helpText2 }} />
                            </div>
                            </>
                        )}
                        <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                        {buttonClass && (
                            <Button 
                            variant="contained" 
                            endIcon={<OpenInNewIcon style={{fontSize: 'medium', lineHeight: 1,
                            }} />}
                            id="procore-team-config" href={modalConfigURL} target="_blank" rel="noopener noreferrer" className={buttonClass}>
                                {linkName}
                            </Button>
                        )}
                       
                          </div>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InfoDialogButton;