import React from "react";

import { Card, CardContent, Typography } from "@material-ui/core";
import { formatDateShort } from "../utilityFunctions";

const GroundBreakDate = ({ projectData }) => {
    const procoreActualStartDate = projectData?.project_data?.actual_start_date || projectData?.actual_start_date;

return (
    <div>
        {projectData ? (
            <Typography style={{ textAlign:'center'}} className="main-key-project-info-body" variant="body1">
                {formatDateShort(procoreActualStartDate) || '--'}
            </Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default GroundBreakDate;