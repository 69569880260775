import React from "react";
import { Typography } from "@material-ui/core";

import { formatDollar } from "../utilityFunctions";

const ChangeEventRevenue = ({ budgetData }) => {
    const changeEventRevenue = () => {
        if (budgetData && budgetData?.current_contract_summary?.change_event_revenue !== undefined && budgetData?.current_contract_summary?.open_pcos !== undefined) {
            const changeEventRev = budgetData?.current_contract_summary?.change_event_revenue
            const openPCOS = budgetData?.current_contract_summary.open_pcos
            const roundedRev = Math.ceil(changeEventRev + openPCOS)
            return formatDollar(roundedRev);
        }
        return '--';
    };
    return (
        <div>
            <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
                {changeEventRevenue()}
            </Typography>
        </div>
    );
}

export default ChangeEventRevenue;