import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { formatDateShort } from "../utilityFunctions";

const SubstantialCompletionDate = ({ projectData }) => {
    const procoreCompletionDate = projectData.project_data?.completion_date || projectData?.completion_date

return (
    <div>
        {projectData ? (
            <Typography style={{ textAlign:'center'}} variant="body1" className='main-key-project-info-body'>{formatDateShort(procoreCompletionDate) || '--'}</Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default SubstantialCompletionDate;