import React from 'react';

import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { CardContent } from '@mui/material';
// PDF Download Library
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import * as XLSX from 'xlsx';
import { formatDollarFull } from '../utilityFunctions';

const useStyles = makeStyles({
    table: {
        minWidth: 500,
        whiteSpace: 'nowrap',
    },
    tableContainer: {
        overflowX: 'auto',
    },
    statusIcon: {
        verticalAlign: 'middle',
        marginRight: '8px',
    },
    red: {
        color: 'red',
        fontSize: 12,
        whiteSpace: 'nowrap',
        padding: '2.5px 9px',
        customFont: {
            fontFamily: "Titillium Web, sans-serif",
        },
    },
    green: {
        color: 'green',
        fontSize: 12,
        whiteSpace: 'nowrap',
        padding: '2.5px 9px',
        customFont: {
            fontFamily: "Titillium Web, sans-serif",
        },
    },
    copyButton: {
        margin: '10px 0',
    },
    excelButton: {
        backgroundColor: '#1e613b',
        color: 'white',
        margin: '10px 0px 10px 10px',
        '&:hover': {
            backgroundColor: '#1e613b',
        },
    }
});

const StyledTableCell = withStyles((theme) => ({
    head: {
        backgroundColor: '#093d61',
        color: theme.palette.common.white,
        fontSize: 15,
        whiteSpace: 'nowrap',
        paddingTop: 7, 
        paddingBottom: 7
   
    },
    body: {
        fontSize: 12,
        whiteSpace: 'nowrap',
        padding: '2.5px 9px',
        customFont: {
            fontFamily: "Titillium Web, sans-serif",
        },
        

    },
}))(TableCell);

const StyledTableRow = withStyles({
    root: {
        '&:nth-of-type(even)': {
            backgroundColor: '#f0f0f0', 
        },
    },
  })(TableRow);

const calculatePaymentAge = (invoiceDate, payFullDate) => {
    if (!invoiceDate || !payFullDate) {
        return null;
    }
    const invoice = new Date(invoiceDate);
    const paid = new Date(payFullDate);

    if (isNaN(invoice) || isNaN(paid)) {
        return null;
    }

    const timeDiff = paid - invoice;
    return Math.ceil(timeDiff / (1000 * 60 * 60 * 24)); 
}

const calculateAveragePaymentAge = (invoiceData) => {
    if (!invoiceData || invoiceData.length === 0) {
        return 0;
    }

    const totalPaymentAge = invoiceData.reduce((acc, invoice) => acc + (invoice.pmt_age || 0), 0);
    return (totalPaymentAge / invoiceData.length).toFixed(2);
};


const PaymentsAndInvoicingTable = ({ invoiceData, isModal, selectedProject }) => {
    const classes = useStyles();
    const averagePaymentAge = calculateAveragePaymentAge(invoiceData);

    if (!invoiceData || invoiceData.length === 0) {
        return (
            <CardContent style={{ height: '200px', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}> 
                <Typography style={{ textAlign: 'center' }}>--</Typography>
            </CardContent>
        )
    }

    const sortedInvoiceData = [...invoiceData].sort(
        (a, b) => new Date(b.invoice_date) - new Date(a.invoice_date)
      );

    return (
        <CardContent >
        <TableContainer className={isModal ? classes.tableContainer : ''} component={Paper} style={{ maxHeight: isModal ? '100%' : '250px' }}>
            <Table className={`${classes.table} ${classes.customFont}`} aria-label="owner billings table">
                <TableHead>
                    <StyledTableRow>
                        <StyledTableCell className="overunder-width-control">Pay App Description</StyledTableCell>
                        <StyledTableCell>Invoice Date</StyledTableCell>
                        <StyledTableCell>Date Paid</StyledTableCell>
                        {/* <StyledTableCell style={{textAlign: 'center'}}>Payment Age</StyledTableCell>  */}
                        <StyledTableCell style={{textAlign: 'right'}}>Invoiced</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'right'}}>Paid</StyledTableCell>
                        <StyledTableCell style={{textAlign: 'right'}}>Cumulative Paid</StyledTableCell>
                    </StyledTableRow>
                </TableHead>
                <TableBody>
                    {sortedInvoiceData.map((row, index) => {
                        // const paymentAge = row.pay_full_date && row.invoice_date ? row.pmt_age : null;
                        return (
                            <StyledTableRow key={index}>
                                <StyledTableCell className="overunder-width-control" title={row.pay_app_description || '--'}>{row.pay_app_description || '--'}</StyledTableCell>
                                <StyledTableCell component="th" scope="row">
                                    {row.invoice_date ? row.invoice_date : '--'}
                                </StyledTableCell>
                                <StyledTableCell>
                                    {row.pay_full_date ? row.pay_full_date : '--'} 
                                </StyledTableCell>
                                {/* <StyledTableCell style={{textAlign: 'center'}}>
                                    {paymentAge !== null ? (
                                        <Typography className={paymentAge >= 30 ? classes.red : classes.green}>
                                            {paymentAge} days
                                        </Typography>
                                    ) : (
                                        '--'
                                    )} 
                                </StyledTableCell> */}
                                <StyledTableCell 
                                    style={{ color: row.invoiced < 0 ? 'red' : 'inherit', textAlign: 'right' }}
                                >
                                    {row.invoiced ? formatDollarFull(row.invoiced) : '--'}
                                </StyledTableCell>
                                <StyledTableCell 
                                    style={{ color: row.paid < 0 ? 'red' : 'inherit', textAlign: 'right' }}
                                >
                                    {row.paid ? formatDollarFull(row.paid) : '--'}
                                </StyledTableCell>
                                <StyledTableCell
                                    style={{textAlign: 'right'}}
                                >{row.cumulative_paid ? formatDollarFull(row.cumulative_paid) : '--'}</StyledTableCell>
                            </StyledTableRow>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    </CardContent>
);
};

export default PaymentsAndInvoicingTable;