import React, { useState, useRef } from "react";
import {
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
  IconButton,
  Collapse,
} from "@material-ui/core";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { CardContent } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import CheckIcon from '@mui/icons-material/Check';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { formatDateShort } from "../utilityFunctions";
// PDF Download Library
import "jspdf-autotable";
import {
  formatDollar,
  formatPercentage,
  colorCodeRed,
} from "../utilityFunctions";
import {fetchProjectBudgetDataBySnapshot} from "../../Containers/api";
import SharedSavingsTable from "./SharedSavingsTableComponent";

const useStyles = makeStyles({
  table: {
    minWidth: 500,
    whiteSpace: "nowrap",
  },
  tableContainer: {
    overflowX: "auto",
  },
  statusIcon: {
    verticalAlign: "middle",
    marginRight: "8px",
  },
  red: {
    color: "red",
  },
  green: {
    color: "green",
  },
  copyButton: {
    margin: "10px 0",
  },
  excelButton: {
    backgroundColor: "#1e613b",
    color: "white",
    margin: "10px 0px 10px 10px",
    "&:hover": {
      backgroundColor: "#1e613b",
    },
  },
});

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#093d61",
    color: theme.palette.common.white,
    fontSize: 14,
    paddingTop: 5,
    paddingBottom: 5,
    whiteSpace: "nowrap",
    width: "16.66%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  body: {
    fontSize: 12,
    paddingTop: 5,
    paddingBottom: 5,
    whiteSpace: "nowrap",
    width: "16.66%",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}))(TableCell);

const StyledTableRow = withStyles({
  root: {
    "&:nth-of-type(even)": {
      backgroundColor: "#f0f0f0",
    },
  },
})(TableRow);


const BVTTablesComponent = ({ budgetData, isModal, selectedSnapshots, fetchProjectBudgetData, projectId }) => {
  const printRef = useRef();
  const classes = useStyles();
  const [showSnapshotControls, setShowSnapshotControls] = useState(false);

  const sortedSnapshots = React.useMemo(() => {
    return [...(selectedSnapshots || [])].sort((a, b) => 
      new Date(a.created_at) - new Date(b.created_at)
    );
  }, [selectedSnapshots]);

  // Initialize state with proper defaults using lazy initial state
  const [selectedBaselineId, setSelectedBaselineId] = useState(() => 
  sortedSnapshots[0]?.id || null
  );
  const [selectedCurrentId, setSelectedCurrentId] = useState(() => 
    sortedSnapshots[sortedSnapshots.length - 1]?.id || null
  );
  const [selectedPenultimateId, setSelectedPenultimateId] = useState(() => 
    sortedSnapshots[sortedSnapshots.length - 2]?.id || null
  );

  const [updatedBudgetData, setUpdatedBudgetData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isComplete, setIsComplete] = useState(false);


  const dataToUse = updatedBudgetData || budgetData;


  const handleFetchBudgetData = async () => {
    if (!selectedBaselineId || !selectedPenultimateId || !selectedCurrentId) {
      return;
    }
    setIsLoading(true);
    setIsComplete(false);
    try {
      const data = await fetchProjectBudgetDataBySnapshot(
        projectId,
        selectedBaselineId,
        selectedPenultimateId,
        selectedCurrentId
      );
      setUpdatedBudgetData(data);
    } catch (error) {
      console.error("Error fetching budget data:", error);
    } finally {
      setIsLoading(false);
      setIsComplete(true);
      setTimeout(() => setIsComplete(false), 1500); // ONly flashing complete for 1.5 sec
    }
  };

  const renderTables = (data) => {
    if (
      !data ||
      !data.baseline_contract_summary?.contract_structure ||
      !data.penultimate_contract_summary?.contract_structure ||
      !data.current_contract_summary?.contract_structure
    ) {
      return <Typography style={{ textAlign: "center" }}>--</Typography>;
    }

    // Destructure from provided data
    const {
      baseline_contract_summary: baseline,
      penultimate_contract_summary: penultimate,
      current_contract_summary: current,
    } = data;

  // Helper function to calculate variance
  const calculateVariance = (a, b) => {
    if (typeof a === "number" && typeof b === "number") {
      return b - a;
    } else {
      return null;
    }
  };

  // Initialize separate GMP and LS Totals for Cost and Budget
  let lumpSumTotals = { baseline: 0, penultimate: 0, current: 0 };
  let gmpTotals = { baseline: 0, penultimate: 0, current: 0 };

  let lumpSumCosts = { baseline: 0, penultimate: 0, current: 0 };
  let gmpCosts = { baseline: 0, penultimate: 0, current: 0 };

  // Variance variables
  let variancePenultimateToCurrentLumpSum = 0,
    varianceBaselineToCurrentLumpSum = 0;
  let variancePenultimateToCurrentGMP = 0,
    varianceBaselineToCurrentGMP = 0;

  let variancePenultimateToCurrentLumpSumCosts = 0,
    varianceBaselineToCurrentLumpSumCosts = 0;
  let variancePenultimateToCurrentGMPCosts = 0,
    varianceBaselineToCurrentGMPCosts = 0;

  // Extract Budet & Cost Items
  const currentBudgetItems = Object.keys(
    current.contract_structure.Budget || {}
  );
  const currentCostItems = Object.keys(current.contract_structure.Cost || {});

  const calculateTotals = (contractSummary, type) => {
    const items = Object.keys(contractSummary.contract_structure[type] || {});
    return items.reduce(
      (totals, item) => {
        const value = contractSummary.contract_structure[type][item] || 0;
        if (item.includes("LS")) {
          totals.lumpSum += value;
        } else if (item.includes("GMP")) {
          totals.gmp += value;
        }
        return totals;
      },
      { lumpSum: 0, gmp: 0 }
    );
  };

  // Calculate Lump Sum and GMP totals for Budget
  const baselineBudgetTotals = calculateTotals(baseline, "Budget");
  const penultimateBudgetTotals = calculateTotals(penultimate, "Budget");
  const currentBudgetTotals = calculateTotals(current, "Budget");

  lumpSumTotals.baseline = baselineBudgetTotals.lumpSum;
  lumpSumTotals.penultimate = penultimateBudgetTotals.lumpSum;
  lumpSumTotals.current = currentBudgetTotals.lumpSum;

  gmpTotals.baseline = baselineBudgetTotals.gmp;
  gmpTotals.penultimate = penultimateBudgetTotals.gmp;
  gmpTotals.current = currentBudgetTotals.gmp;

  // Calculate variances for Lump Sum Budget
  variancePenultimateToCurrentLumpSum = calculateVariance(
    lumpSumTotals.penultimate,
    lumpSumTotals.current
  );
  varianceBaselineToCurrentLumpSum = calculateVariance(
    lumpSumTotals.baseline,
    lumpSumTotals.current
  );

  // Calculate variances for GMP Budget
  variancePenultimateToCurrentGMP = calculateVariance(
    gmpTotals.penultimate,
    gmpTotals.current
  );
  varianceBaselineToCurrentGMP = calculateVariance(
    gmpTotals.baseline,
    gmpTotals.current
  );

  const baselineCostTotals = calculateTotals(baseline, "Cost");
  const penultimateCostTotals = calculateTotals(penultimate, "Cost");
  const currentCostTotals = calculateTotals(current, "Cost");

  lumpSumCosts.baseline = baselineCostTotals.lumpSum;
  lumpSumCosts.penultimate = penultimateCostTotals.lumpSum;
  lumpSumCosts.current = currentCostTotals.lumpSum;

  gmpCosts.baseline = baselineCostTotals.gmp;
  gmpCosts.penultimate = penultimateCostTotals.gmp;
  gmpCosts.current = currentCostTotals.gmp;

  // Calculate variances for Lump Sum Costs
  variancePenultimateToCurrentLumpSumCosts = calculateVariance(
    lumpSumCosts.penultimate,
    lumpSumCosts.current
  );
  varianceBaselineToCurrentLumpSumCosts = calculateVariance(
    lumpSumCosts.baseline,
    lumpSumCosts.current
  );

  // Calculate variances for GMP Costs
  variancePenultimateToCurrentGMPCosts = calculateVariance(
    gmpCosts.penultimate,
    gmpCosts.current
  );
  varianceBaselineToCurrentGMPCosts = calculateVariance(
    gmpCosts.baseline,
    gmpCosts.current
  );

  // Initialize totals
  // Budget
  let baselineTotal = 0,
    penultimateTotal = 0,
    currentTotal = 0;
  let variancePenultimateToCurrentTotal = 0,
    varianceBaselineToCurrentTotal = 0;
  let baselineAllowanceTotal = 0,
    penultimateAllowanceTotal = 0,
    currentAllowanceTotal = 0;
  let baselineMarkupsTotal = 0,
    penultimateMarkupsTotal = 0,
    currentMarkupsTotal = 0;
  let baselineTotalBudgetContract = 0,
    penultimateTotalBudgetContract = 0,
    currentTotalBudgetContract = 0;

  // Costs
  let baselineCostTotal = 0,
    penultimateCostTotal = 0,
    currentCostTotal = 0;
  let variancePenultimateToCurrentTotalCost = 0,
    varianceBaselineToCurrentTotalCost = 0;
  let baselineCostAllowanceTotal = 0,
    penultimateCostAllowanceTotal = 0,
    currentCostAllowanceTotal = 0;
  let baselineCostMarkupsTotal = 0,
    penultimateCostMarkupsTotal = 0,
    currentCostMarkupsTotal = 0;
  let baselineTotalCostContract = 0,
    penultimateTotalCostContract = 0,
    currentTotalCostContract = 0;

  // Over/Under Totals
  let baselineOverUnderTotal = 0,
    penultimateOverUnderTotal = 0,
    currentOverUnderTotal = 0;
  let variancePenultimateToCurrentOverUnder = 0,
    varianceBaselineToCurrentOverUnder = 0;

  // Total Current Budget
  const calculateTotalBudget = (budgetData) => {
    const { current_contract_summary: current } = budgetData;
    const currentBudgetItems = Object.keys(
      current.contract_structure.Budget || {}
    );

    const totalBudget = currentBudgetItems.reduce((total, item) => {
      const itemValue = current.contract_structure.Budget[item] || 0;
      return total + itemValue;
    }, 0);

    return totalBudget;
  };

  let totalCurrentBudget = calculateTotalBudget(data);

  // Helper for Calculating Contract Totals
  const calculateTotalContractValues = (a, b, c) => {
    return a + b + c;
  };

  const currentOverUnderItems = Object.keys(
    current.contract_structure["Over/Under"] || {}
  );
  const currentJTDCostsItems = Object.keys(
    current.contract_structure["JTD Costs"] || {}
  );

  currentOverUnderItems.forEach((item) => {
    const baselineValue = baseline.contract_structure["Over/Under"][item] || 0;
    const penultimateValue =
      penultimate.contract_structure["Over/Under"][item] || 0;
    const currentValue = current.contract_structure["Over/Under"][item] || 0;

    baselineOverUnderTotal += baselineValue;
    penultimateOverUnderTotal += penultimateValue;
    currentOverUnderTotal += currentValue;

    variancePenultimateToCurrentOverUnder += calculateVariance(
      penultimateValue,
      currentValue
    );
    varianceBaselineToCurrentOverUnder += calculateVariance(
      baselineValue,
      currentValue
    );
  });

  if (isModal === false) {
    return (
      <>
        <CardContent>
          {/* <p className="bvt-table-title">Job to Date Costs</p> */}
        </CardContent>
      </>
    );
  } else {
    return (
      <div key={sortedSnapshots.map(s => s.id).join('-')}>
      <CardContent>
      <div>
      <div className="snapshot-control-container">
            {/* Toggle Header with Expand/Collapse Button */}
            <div style={{ display: "flex", alignItems: "center", cursor: 'pointer', maxWidth: '300px'}}
            onClick={() => setShowSnapshotControls(!showSnapshotControls)}>
              <Typography className="titillium-web-base" style={{ marginLeft: '-5px', fontSize: '1.25rem' }}>
                Select Snapshots to Compare
              </Typography>
              <IconButton
                size="small"
                style={{ marginLeft: "8px" }}
              >
                {showSnapshotControls ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </div>

            {/* Collapsible Section */}
            <Collapse in={showSnapshotControls}>
              <div style={{ alignItems: "center", marginTop: "10px" }}>
                {/* Dropdowns for Snapshot Selection */}
                <FormControl style={{ marginRight: "20px", minWidth: "200px" }}>
                  <InputLabel>Snapshot A</InputLabel>
                  <Select
                    value={selectedPenultimateId || ""}
                    onChange={(e) => {
                      const newPenultimateId = e.target.value;
                      setSelectedPenultimateId(newPenultimateId);

                      // Validate and update Month 2 (selectedCurrentId)
                      const selectedIndex = sortedSnapshots.findIndex(
                        (snapshot) => snapshot.id === newPenultimateId
                      );

                      // Ensure selectedCurrentId is after the new Month 1
                      if (
                        selectedIndex >= sortedSnapshots.length - 1 ||
                        sortedSnapshots.findIndex((snapshot) => snapshot.id === selectedCurrentId) <= selectedIndex
                      ) {
                        setSelectedCurrentId(sortedSnapshots[selectedIndex + 1]?.id || null);
                      }
                    }}
                  >
                    {sortedSnapshots.map(snapshot => (
                      <MenuItem key={snapshot.id} value={snapshot.id}>
                        {snapshot.name} - {formatDateShort(snapshot.created_at)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                <FormControl style={{ marginRight: "20px", minWidth: "200px" }}>
                  <InputLabel>Snapshot B</InputLabel>
                  <Select
                    value={selectedCurrentId || ""}
                    onChange={(e) => setSelectedCurrentId(e.target.value)}
                  >
                    {sortedSnapshots
                      .filter(
                        (snapshot) =>
                          new Date(snapshot.created_at) >
                          new Date(sortedSnapshots.find((s) => s.id === selectedPenultimateId)?.created_at || 0)
                      )
                      .map((snapshot) => (
                        <MenuItem key={snapshot.id} value={snapshot.id}>
                          {snapshot.name} - {formatDateShort(snapshot.created_at)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>

                <Button
                  variant="contained"
                  className="smaller-primary-button"
                  onClick={handleFetchBudgetData}
                  disabled={!selectedBaselineId || !selectedCurrentId || !selectedPenultimateId}
                >
                  Update Numbers
                </Button>
              </div>

              {/* Loading and Complete Status */}
              {isLoading && (
                <div style={{ display: "flex", justifyContent: "center", marginTop: "20px" }}>
                  <CircularProgress size={20} style={{ marginRight: 8 }} />
                  <Typography variant="body2" className="titillium-web">Loading updated information...</Typography>
                </div>
              )}
              {isComplete && (
                <div style={{ textAlign: "center", marginTop: "20px", color: "green", display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                  <Typography variant="body2" className="titillium-web">Complete!</Typography><CheckIcon fontSize="small" />
                </div>
              )}
            </Collapse>
             {/* Snapshot Selection Display */}
             <div style={{ marginBottom: "20px", display: 'flex', flexDirection: 'column', marginTop: '15px' }}>
                <Typography className="snapshot-selections-display">
                  Baseline: {sortedSnapshots.find(s => s.id === selectedBaselineId)?.name} ( Captured on {formatDateShort(sortedSnapshots.find(s => s.id === selectedBaselineId)?.created_at)} )
                </Typography>
                <Typography className="snapshot-selections-display">
                  Snapshot A: {sortedSnapshots.find(s => s.id === selectedPenultimateId)?.name || 'N/A'} ( Captured on {formatDateShort(sortedSnapshots.find(s => s.id === selectedPenultimateId)?.created_at)} )
                </Typography>
                <Typography className="snapshot-selections-display">
                  Snapshot B: {sortedSnapshots.find(s => s.id === selectedCurrentId)?.name} ( Captured on {formatDateShort(sortedSnapshots.find(s => s.id === selectedCurrentId)?.created_at)} )
                </Typography>
              </div>
          </div>
          </div>
        {/* =========================================== */}
        {/* ============= Budget Table ================ */}
        {/* =========================================== */}
        <p className="bvt-table-title">Budget</p>
        <TableContainer
          component={Paper}
          style={{ maxHeight: isModal ? "100%" : "250px" }}
        >
          <Table className={classes.table} aria-label="bvt-budget-table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Baseline</StyledTableCell>
                <StyledTableCell>Snapshot A</StyledTableCell>
                <StyledTableCell>Snapshot B</StyledTableCell>
                <StyledTableCell>Snapshot A to B Variance</StyledTableCell>
                <StyledTableCell>Baseline to Snapshot B Variance</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {/* Process Allowance and Financial Markups Budget */}
              {currentBudgetItems.forEach((item) => {
                const baselineValue =
                  baseline.contract_structure.Budget[item] || 0;
                const penultimateValue =
                  penultimate.contract_structure.Budget[item] || 0;
                const currentValue =
                  current.contract_structure.Budget[item] || 0;

                if (item === "Allowance") {
                  baselineAllowanceTotal += baselineValue;
                  penultimateAllowanceTotal += penultimateValue;
                  currentAllowanceTotal += currentValue;
                } else if (item === "Financial Markups") {
                  baselineMarkupsTotal += baselineValue;
                  penultimateMarkupsTotal += penultimateValue;
                  currentMarkupsTotal += currentValue;
                }
              })}

              {/* Lump Sum Budget Section */}
              <StyledTableRow>
                <StyledTableCell>Lump Sum Budget</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
              </StyledTableRow>
              {currentBudgetItems
                .filter((item) => item.includes("LS"))
                .map((item) => {
                  const baselineValue =
                    baseline.contract_structure.Budget[item] || 0;
                  const penultimateValue =
                    penultimate.contract_structure.Budget[item] || 0;
                  const currentValue =
                    current.contract_structure.Budget[item] || 0;
                  const variancePenultimateToCurrent = calculateVariance(
                    penultimateValue,
                    currentValue
                  );
                  const varianceBaselineToCurrent = calculateVariance(
                    baselineValue,
                    currentValue
                  );

                  return (
                    <StyledTableRow key={item}>
                      <StyledTableCell className="bvt-table-lineitem">
                        {item}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDollar(baselineValue)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDollar(penultimateValue)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDollar(currentValue)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            variancePenultimateToCurrent < 0
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {formatDollar(variancePenultimateToCurrent)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            varianceBaselineToCurrent < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(varianceBaselineToCurrent)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {/* Lump Sum Budget Total */}
              <StyledTableRow className="bvt-table-total">
                <StyledTableCell className="bvt-table-lineitem">Lump Sum Budget Total</StyledTableCell>
                <StyledTableCell
                  style={{
                    color: lumpSumTotals.baseline < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(lumpSumTotals.baseline)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: lumpSumTotals.penultimate < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(lumpSumTotals.penultimate)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: lumpSumTotals.current < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(lumpSumTotals.current)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      variancePenultimateToCurrentLumpSum < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(variancePenultimateToCurrentLumpSum)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      varianceBaselineToCurrentLumpSum < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(varianceBaselineToCurrentLumpSum)}
                </StyledTableCell>
              </StyledTableRow>

              {/* GMP Budget Section */}
              <StyledTableRow>
                <StyledTableCell>GMP Budget</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
              </StyledTableRow>
              {currentBudgetItems
                .filter((item) => item.includes("GMP"))
                .map((item) => {
                  const baselineValue =
                    baseline.contract_structure.Budget[item] || 0;
                  const penultimateValue =
                    penultimate.contract_structure.Budget[item] || 0;
                  const currentValue =
                    current.contract_structure.Budget[item] || 0;
                  const variancePenultimateToCurrent = calculateVariance(
                    penultimateValue,
                    currentValue
                  );
                  const varianceBaselineToCurrent = calculateVariance(
                    baselineValue,
                    currentValue
                  );

                  return (
                    <StyledTableRow key={item}>
                      <StyledTableCell className="bvt-table-lineitem">
                        {item}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDollar(baselineValue)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDollar(penultimateValue)}
                      </StyledTableCell>
                      <StyledTableCell>
                        {formatDollar(currentValue)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            variancePenultimateToCurrent < 0
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {formatDollar(variancePenultimateToCurrent)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            varianceBaselineToCurrent < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(varianceBaselineToCurrent)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {/* GMP Budget Total */}
              <StyledTableRow className="bvt-table-total">
                <StyledTableCell className="bvt-table-lineitem">GMP Budget Total</StyledTableCell>
                <StyledTableCell
                  style={{ color: gmpTotals.baseline < 0 ? "red" : "inherit" }}
                >
                  {formatDollar(gmpTotals.baseline)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: gmpTotals.penultimate < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(gmpTotals.penultimate)}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: gmpTotals.current < 0 ? "red" : "inherit" }}
                >
                  {formatDollar(gmpTotals.current)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      variancePenultimateToCurrentGMP < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(variancePenultimateToCurrentGMP)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: varianceBaselineToCurrentGMP < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(varianceBaselineToCurrentGMP)}
                </StyledTableCell>
              </StyledTableRow>

              {/* Allowance Budget */}
              <StyledTableRow>
                <StyledTableCell>Allowance Budget</StyledTableCell>
                <StyledTableCell
                  style={{
                    color: baselineAllowanceTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(baselineAllowanceTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: penultimateAllowanceTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(penultimateAllowanceTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: currentAllowanceTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(currentAllowanceTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        penultimateAllowanceTotal,
                        currentAllowanceTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      penultimateAllowanceTotal,
                      currentAllowanceTotal
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        baselineAllowanceTotal,
                        currentAllowanceTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      baselineAllowanceTotal,
                      currentAllowanceTotal
                    )
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* Financial Markups Budget */}
              <StyledTableRow>
                <StyledTableCell>Financial Markups Budget</StyledTableCell>
                <StyledTableCell>
                  {formatDollar(baselineMarkupsTotal)}
                </StyledTableCell>
                <StyledTableCell>
                  {formatDollar(penultimateMarkupsTotal)}
                </StyledTableCell>
                <StyledTableCell>
                  {formatDollar(currentMarkupsTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        penultimateMarkupsTotal,
                        currentMarkupsTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      penultimateMarkupsTotal,
                      currentMarkupsTotal
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        baselineMarkupsTotal,
                        currentMarkupsTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(baselineMarkupsTotal, currentMarkupsTotal)
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* Total Budget Contract Values */}
              <StyledTableRow className="bvt-table-total-b">
                <StyledTableCell>Total Budget Contract Values</StyledTableCell>
                <StyledTableCell>
                  {formatDollar(
                    lumpSumTotals.baseline +
                      gmpTotals.baseline +
                      baselineAllowanceTotal +
                      baselineMarkupsTotal
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {formatDollar(
                    lumpSumTotals.penultimate +
                      gmpTotals.penultimate +
                      penultimateAllowanceTotal +
                      penultimateMarkupsTotal
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {formatDollar(
                    lumpSumTotals.current +
                      gmpTotals.current +
                      currentAllowanceTotal +
                      currentMarkupsTotal
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        lumpSumTotals.penultimate +
                          gmpTotals.penultimate +
                          penultimateAllowanceTotal +
                          penultimateMarkupsTotal,
                        lumpSumTotals.current +
                          gmpTotals.current +
                          currentAllowanceTotal +
                          currentMarkupsTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      lumpSumTotals.penultimate +
                        gmpTotals.penultimate +
                        penultimateAllowanceTotal +
                        penultimateMarkupsTotal,
                      lumpSumTotals.current +
                        gmpTotals.current +
                        currentAllowanceTotal +
                        currentMarkupsTotal
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        lumpSumTotals.baseline +
                          gmpTotals.baseline +
                          baselineAllowanceTotal +
                          baselineMarkupsTotal,
                        lumpSumTotals.current +
                          gmpTotals.current +
                          currentAllowanceTotal +
                          currentMarkupsTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      lumpSumTotals.baseline +
                        gmpTotals.baseline +
                        baselineAllowanceTotal +
                        baselineMarkupsTotal,
                      lumpSumTotals.current +
                        gmpTotals.current +
                        currentAllowanceTotal +
                        currentMarkupsTotal
                    )
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {/* ====================================================================== */}
        {/* ========================= Costs BVT Table ============================ */}
        {/* ====================================================================== */}
        <p className="bvt-table-title">Costs</p>
        <TableContainer
          component={Paper}
          style={{ maxHeight: isModal ? "100%" : "250px" }}
        >
          <Table className={classes.table} aria-label="bvt-costs-table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Baseline</StyledTableCell>
                <StyledTableCell>Snapshot A</StyledTableCell>
                <StyledTableCell>Snapshot B</StyledTableCell>
                <StyledTableCell>Snapshot A to B Variance</StyledTableCell>
                <StyledTableCell>Baseline to Snapshot B Variance</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {/* Process Allowance and Financial Markups Costs */}
              {currentCostItems.forEach((item) => {
                const baselineValue =
                  baseline.contract_structure.Cost[item] || 0;
                const penultimateValue =
                  penultimate.contract_structure.Cost[item] || 0;
                const currentValue = current.contract_structure.Cost[item] || 0;

                if (item === "Allowance") {
                  baselineCostAllowanceTotal += baselineValue;
                  penultimateCostAllowanceTotal += penultimateValue;
                  currentCostAllowanceTotal += currentValue;
                } else if (item === "Financial Markups") {
                  baselineCostMarkupsTotal += baselineValue;
                  penultimateCostMarkupsTotal += penultimateValue;
                  currentCostMarkupsTotal += currentValue;
                }
              })}

              {/* Lump Sum Costs Section */}
              <StyledTableRow>
                <StyledTableCell>Lump Sum Costs</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
              </StyledTableRow>
              {currentCostItems
                .filter((item) => item.includes("LS"))
                .map((item) => {
                  const baselineValue =
                    baseline.contract_structure.Cost[item] || 0;
                  const penultimateValue =
                    penultimate.contract_structure.Cost[item] || 0;
                  const currentValue =
                    current.contract_structure.Cost[item] || 0;
                  const variancePenultimateToCurrent = calculateVariance(
                    penultimateValue,
                    currentValue
                  );
                  const varianceBaselineToCurrent = calculateVariance(
                    baselineValue,
                    currentValue
                  );

                  return (
                    <StyledTableRow key={item}>
                      <StyledTableCell className="bvt-table-lineitem">
                        {item}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ color: baselineValue < 0 ? "red" : "inherit" }}
                      >
                        {formatDollar(baselineValue)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: penultimateValue < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(penultimateValue)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ color: currentValue < 0 ? "red" : "inherit" }}
                      >
                        {formatDollar(currentValue)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            variancePenultimateToCurrent < 0
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {formatDollar(variancePenultimateToCurrent)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            varianceBaselineToCurrent < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(varianceBaselineToCurrent)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {/* Lump Sum Costs Total */}
              <StyledTableRow className="bvt-table-total">
                <StyledTableCell className="bvt-table-lineitem">Lump Sum Costs Total</StyledTableCell>
                <StyledTableCell
                  style={{
                    color: lumpSumCosts.baseline < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(lumpSumCosts.baseline)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: lumpSumCosts.penultimate < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(lumpSumCosts.penultimate)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: lumpSumCosts.current < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(lumpSumCosts.current)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      variancePenultimateToCurrentLumpSumCosts < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(variancePenultimateToCurrentLumpSumCosts)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      varianceBaselineToCurrentLumpSumCosts < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(varianceBaselineToCurrentLumpSumCosts)}
                </StyledTableCell>
              </StyledTableRow>

              {/* GMP Costs Section */}
              <StyledTableRow>
                <StyledTableCell>GMP Costs</StyledTableCell>
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
                <StyledTableCell />
              </StyledTableRow>
              {currentCostItems
                .filter((item) => item.includes("GMP"))
                .map((item) => {
                  const baselineValue =
                    baseline.contract_structure.Cost[item] || 0;
                  const penultimateValue =
                    penultimate.contract_structure.Cost[item] || 0;
                  const currentValue =
                    current.contract_structure.Cost[item] || 0;
                  const variancePenultimateToCurrent = calculateVariance(
                    penultimateValue,
                    currentValue
                  );
                  const varianceBaselineToCurrent = calculateVariance(
                    baselineValue,
                    currentValue
                  );

                  return (
                    <StyledTableRow key={item}>
                      <StyledTableCell className="bvt-table-lineitem">
                        {item}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ color: baselineValue < 0 ? "red" : "inherit" }}
                      >
                        {formatDollar(baselineValue)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: penultimateValue < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(penultimateValue)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{ color: currentValue < 0 ? "red" : "inherit" }}
                      >
                        {formatDollar(currentValue)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            variancePenultimateToCurrent < 0
                              ? "red"
                              : "inherit",
                        }}
                      >
                        {formatDollar(variancePenultimateToCurrent)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            varianceBaselineToCurrent < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(varianceBaselineToCurrent)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {/* GMP Costs Total */}
              <StyledTableRow className="bvt-table-total">
                <StyledTableCell className="bvt-table-lineitem">GMP Costs Total</StyledTableCell>
                <StyledTableCell
                  style={{ color: gmpCosts.baseline < 0 ? "red" : "inherit" }}
                >
                  {formatDollar(gmpCosts.baseline)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: gmpCosts.penultimate < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(gmpCosts.penultimate)}
                </StyledTableCell>
                <StyledTableCell
                  style={{ color: gmpCosts.current < 0 ? "red" : "inherit" }}
                >
                  {formatDollar(gmpCosts.current)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      variancePenultimateToCurrentGMPCosts < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(variancePenultimateToCurrentGMPCosts)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      varianceBaselineToCurrentGMPCosts < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(varianceBaselineToCurrentGMPCosts)}
                </StyledTableCell>
              </StyledTableRow>

              {/* Allowance Costs */}
              <StyledTableRow>
                <StyledTableCell>Allowance Costs</StyledTableCell>
                <StyledTableCell
                  style={{
                    color: baselineCostAllowanceTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(baselineCostAllowanceTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      penultimateCostAllowanceTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(penultimateCostAllowanceTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: currentCostAllowanceTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(currentCostAllowanceTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        penultimateCostAllowanceTotal,
                        currentCostAllowanceTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      penultimateCostAllowanceTotal,
                      currentCostAllowanceTotal
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        baselineCostAllowanceTotal,
                        currentCostAllowanceTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      baselineCostAllowanceTotal,
                      currentCostAllowanceTotal
                    )
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* Financial Markups Costs */}
              <StyledTableRow>
                <StyledTableCell>Financial Markups Costs</StyledTableCell>
                <StyledTableCell
                  style={{
                    color: baselineCostMarkupsTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(baselineCostMarkupsTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: penultimateCostMarkupsTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(penultimateCostMarkupsTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: currentCostMarkupsTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(currentCostMarkupsTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        penultimateCostMarkupsTotal,
                        currentCostMarkupsTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      penultimateCostMarkupsTotal,
                      currentCostMarkupsTotal
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        baselineCostMarkupsTotal,
                        currentCostMarkupsTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      baselineCostMarkupsTotal,
                      currentCostMarkupsTotal
                    )
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* Total Cost Contract Values */}
              <StyledTableRow className="bvt-table-total-b">
                <StyledTableCell>Total Cost Contract Values</StyledTableCell>
                <StyledTableCell>
                  {formatDollar(
                    lumpSumCosts.baseline +
                      gmpCosts.baseline +
                      baselineCostAllowanceTotal +
                      baselineCostMarkupsTotal
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {formatDollar(
                    lumpSumCosts.penultimate +
                      gmpCosts.penultimate +
                      penultimateCostAllowanceTotal +
                      penultimateCostMarkupsTotal
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {formatDollar(
                    lumpSumCosts.current +
                      gmpCosts.current +
                      currentCostAllowanceTotal +
                      currentCostMarkupsTotal
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        lumpSumCosts.penultimate +
                          gmpCosts.penultimate +
                          penultimateCostAllowanceTotal +
                          penultimateCostMarkupsTotal,
                        lumpSumCosts.current +
                          gmpCosts.current +
                          currentCostAllowanceTotal +
                          currentCostMarkupsTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      lumpSumCosts.penultimate +
                        gmpCosts.penultimate +
                        penultimateCostAllowanceTotal +
                        penultimateCostMarkupsTotal,
                      lumpSumCosts.current +
                        gmpCosts.current +
                        currentCostAllowanceTotal +
                        currentCostMarkupsTotal
                    )
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      calculateVariance(
                        lumpSumCosts.baseline +
                          gmpCosts.baseline +
                          baselineCostAllowanceTotal +
                          baselineCostMarkupsTotal,
                        lumpSumCosts.current +
                          gmpCosts.current +
                          currentCostAllowanceTotal +
                          currentCostMarkupsTotal
                      ) < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    calculateVariance(
                      lumpSumCosts.baseline +
                        gmpCosts.baseline +
                        baselineCostAllowanceTotal +
                        baselineCostMarkupsTotal,
                      lumpSumCosts.current +
                        gmpCosts.current +
                        currentCostAllowanceTotal +
                        currentCostMarkupsTotal
                    )
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* ============================================================ */}
        {/* ============= Total Busts/Pickups BVT Table ================ */}
        {/* ============================================================ */}
        <p className="bvt-table-title">Total Busts/Pickups</p>
        <TableContainer
          component={Paper}
          style={{ maxHeight: isModal ? "100%" : "250px" }}
        >
          <Table className={classes.table} aria-label="bvt-busts-pickups-table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Baseline</StyledTableCell>
                <StyledTableCell>Snapshot A</StyledTableCell>
                <StyledTableCell>Snapshot B</StyledTableCell>
                <StyledTableCell>Snapshot A to B Variance</StyledTableCell>
                <StyledTableCell>Baseline to Snapshot B Variance</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {currentOverUnderItems.map((item) => {
                const baselineValue =
                  baseline.contract_structure["Over/Under"][item] || 0;
                const penultimateValue =
                  penultimate.contract_structure["Over/Under"][item] || 0;
                const currentValue =
                  current.contract_structure["Over/Under"][item] || 0;
                const monthOverMonthVariance =
                  calculateVariance(penultimateValue, currentValue) || 0;
                const baselineVariance =
                  calculateVariance(baselineValue, currentValue) || 0;

                return (
                  <StyledTableRow key={item}>
                    <StyledTableCell>{item}</StyledTableCell>
                    <StyledTableCell
                      style={{ color: baselineValue < 0 ? "red" : "inherit" }}
                    >
                      {formatDollar(baselineValue)}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: penultimateValue < 0 ? "red" : "inherit",
                      }}
                    >
                      {formatDollar(penultimateValue)}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{ color: currentValue < 0 ? "red" : "inherit" }}
                    >
                      {formatDollar(currentValue)}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: monthOverMonthVariance < 0 ? "red" : "inherit",
                      }}
                    >
                      {formatDollar(monthOverMonthVariance)}
                    </StyledTableCell>
                    <StyledTableCell
                      style={{
                        color: baselineVariance < 0 ? "red" : "inherit",
                      }}
                    >
                      {formatDollar(baselineVariance)}
                    </StyledTableCell>
                  </StyledTableRow>
                );
              })}

              {/* Total Busts/Pickups */}
              <StyledTableRow className="bvt-table-total-b">
                <StyledTableCell>Total Busts/Pickups</StyledTableCell>
                <StyledTableCell
                  style={{
                    color: baselineOverUnderTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(baselineOverUnderTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: penultimateOverUnderTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(penultimateOverUnderTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color: currentOverUnderTotal < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(currentOverUnderTotal)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      variancePenultimateToCurrentOverUnder < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(variancePenultimateToCurrentOverUnder)}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      varianceBaselineToCurrentOverUnder < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(varianceBaselineToCurrentOverUnder)}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <p className="bvt-table-title">Summary Level 2 (GC/GR Spend)</p>
        <TableContainer
          component={Paper}
          style={{ maxHeight: isModal ? "100%" : "250px" }}
        >
          <Table className={classes.table} aria-label="jtd-costs-table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Baseline</StyledTableCell>
                <StyledTableCell>Snapshot A</StyledTableCell>
                <StyledTableCell>Snapshot B</StyledTableCell>
                <StyledTableCell>Snapshot A to B Variance</StyledTableCell>
                <StyledTableCell>Total Estimated Budget Remaining</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {currentJTDCostsItems
                .filter((item) => item === "LS-GC/GR")
                .map((item) => {
                  const baselineValueJTD =
                    baseline.contract_structure["JTD Costs"][item] || 0;
                  const penultimateValueJTD =
                    penultimate.contract_structure["JTD Costs"][item] || 0;
                  const currentValueJTD =
                    current.contract_structure["JTD Costs"][item] || 0;
                  const monthOverMonthVarianceJTD = calculateVariance(
                    penultimateValueJTD,
                    currentValueJTD
                  );
                  const totalBustPickupJTD = calculateVariance(
                    baselineValueJTD,
                    currentValueJTD
                  );

                  return (
                    <StyledTableRow key={item}>
                      <StyledTableCell>{item}</StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: baselineValueJTD < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(baselineValueJTD)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: penultimateValueJTD < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(penultimateValueJTD)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: currentValueJTD < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(currentValueJTD)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color:
                            monthOverMonthVarianceJTD < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(monthOverMonthVarianceJTD)}
                      </StyledTableCell>
                      <StyledTableCell
                        style={{
                          color: totalBustPickupJTD < 0 ? "red" : "inherit",
                        }}
                      >
                        {formatDollar(totalBustPickupJTD)}
                      </StyledTableCell>
                    </StyledTableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>

        <p className="bvt-table-title">
          Summary Level 3 (Owner Change Order Analysis)
        </p>
        <TableContainer
          component={Paper}
          style={{ maxHeight: isModal ? "100%" : "250px" }}
        >
          <Table className={classes.table} aria-label="summary-level-3-table">
            <TableHead>
              <StyledTableRow>
                <StyledTableCell>Category</StyledTableCell>
                <StyledTableCell>Total Overall ($)</StyledTableCell>
                <StyledTableCell>% of Budget</StyledTableCell>
                <StyledTableCell>Monthly Variance</StyledTableCell>
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {/* Change Events Revenue */}
              <StyledTableRow>
                <StyledTableCell>Change Events Revenue</StyledTableCell>
                <StyledTableCell
                  style={{
                    color: current.change_event_revenue < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(current.change_event_revenue)}
                </StyledTableCell>
                <StyledTableCell>
                  {formatPercentage(
                    (current.change_event_revenue / totalCurrentBudget) * 100
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      current.change_event_revenue -
                        penultimate.change_event_revenue <
                      0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    current.change_event_revenue -
                      penultimate.change_event_revenue
                  )}
                </StyledTableCell>
              </StyledTableRow>
              {/* Open PCOs */}
              <StyledTableRow>
                <StyledTableCell>Open PCOs</StyledTableCell>
                <StyledTableCell
                  style={{ color: current.open_pcos < 0 ? "red" : "inherit" }}
                >
                  {formatDollar(current.open_pcos)}
                </StyledTableCell>
                <StyledTableCell>
                  {formatPercentage(
                    (current.open_pcos / totalCurrentBudget) * 100
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      current.open_pcos - penultimate.open_pcos < 0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(current.open_pcos - penultimate.open_pcos)}
                </StyledTableCell>
              </StyledTableRow>

              {/* Approved PCOs & PCCOs */}
              <StyledTableRow>
                <StyledTableCell>Approved PCOs & PCCOs</StyledTableCell>
                <StyledTableCell
                  style={{
                    color: current.approved_pcos_pccos < 0 ? "red" : "inherit",
                  }}
                >
                  {formatDollar(current.approved_pcos_pccos)}
                </StyledTableCell>
                <StyledTableCell>
                  {formatPercentage(
                    (current.approved_pcos_pccos / totalCurrentBudget) * 100
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      current.approved_pcos_pccos -
                        penultimate.approved_pcos_pccos <
                      0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    current.approved_pcos_pccos -
                      penultimate.approved_pcos_pccos
                  )}
                </StyledTableCell>
              </StyledTableRow>

              {/* Totals */}
              <StyledTableRow
                className="bvt-table-total-b"
                style={{ backgroundColor: "white" }}
              >
                <StyledTableCell>Total</StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      current.change_event_revenue +
                        current.open_pcos +
                        current.approved_pcos_pccos <
                      0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    current.change_event_revenue +
                      current.open_pcos +
                      current.approved_pcos_pccos
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {formatPercentage(
                    ((current.change_event_revenue +
                      current.open_pcos +
                      current.approved_pcos_pccos) /
                      totalCurrentBudget) *
                      100
                  )}
                </StyledTableCell>
                <StyledTableCell
                  style={{
                    color:
                      current.change_event_revenue +
                        current.open_pcos +
                        current.approved_pcos_pccos -
                        (penultimate.open_pcos +
                          penultimate.approved_pcos_pccos +
                          penultimate.change_event_revenue) <
                      0
                        ? "red"
                        : "inherit",
                  }}
                >
                  {formatDollar(
                    current.change_event_revenue +
                      current.open_pcos +
                      current.approved_pcos_pccos -
                      (penultimate.open_pcos +
                        penultimate.approved_pcos_pccos +
                        penultimate.change_event_revenue)
                  )}
                </StyledTableCell>
              </StyledTableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </CardContent>
      
      <SharedSavingsTable budgetData={budgetData} classes={classes} />

      </div>
    );
  }


};
return renderTables(dataToUse);
}

export default BVTTablesComponent;
