import React from 'react';
import { useState } from 'react';
import { GoogleMap, LoadScript, Marker, InfoWindow } from '@react-google-maps/api';
import { CardContent, Typography, Button } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { borderRadius } from '@mui/system';
import SatteliteAltIcon from '@mui/icons-material/SatelliteAlt';

const containerStyle = {
  width: '100%',
  height: '300px',
  borderRadius: '10px',
  boxShadow: '7px 7px 7px grey'
};

const mapOptions = {
    disableDefaultUI: true,
    zoomControl: true,
    zoom: 25,
    mapTypeId: 'terrain',
    mapTypeControl: true,
    fullscreenControl: true,
    styles: [
        {
            "featureType": "all",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#000000"
                },
                {
                    "lightness": 13
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "administrative",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#144b53"
                },
                {
                    "lightness": 14
                },
                {
                    "weight": 1.4
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#08304b"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#0c4152"
                },
                {
                    "lightness": 5
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#0b434f"
                },
                {
                    "lightness": 25
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#0b3d51"
                },
                {
                    "lightness": 16
                }
            ]
        },
        {
            "featureType": "road.local",
            "elementType": "geometry",
            "stylers": [
                {
                    "color": "#000000"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#146474"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#021019"
                }
            ]
        }
    ]
}
  
const ProjectLocationMap = ({ projectData, gmapsAPI }) => {
    const [infoWindowOpen, setInfoWindowOpen] = useState(false);

    let center = null;
    let project_address = null;
    let front_display_address = null;
    if (!projectData || projectData.length === 0) {
        return (
            <CardContent>
            <div style={{ textAlign: 'center' }}>
                <Typography variant="body1">No project data available</Typography>
            </div>
            </CardContent>
        );
        }
       

    if (projectData && projectData.project_data && projectData.project_data.latitude) {
      const lat = projectData.project_data.latitude;
      const lng = projectData.project_data.longitude;
      const address = projectData.project_data.address
      const city = projectData.project_data.city
      const state = projectData.project_data.state
      const zip = projectData.project_data.zip
      const country = projectData.project_data.country 

      center = {
        lat: parseFloat(lat),
        lng: parseFloat(lng)
      };
      if (!address) {
        project_address = "Project lacking exact address info.";
        front_display_address = null;

      } else {
        project_address = `${address}, ${city}, ${state}`;
        front_display_address = `${address}, ${city}, ${state}`;
      }

    }

    // Open map in new browser window
    const openMap = () => {
        if (center) {
            const googleMapsUrl = `https://www.google.com/maps?q=${center.lat},${center.lng}`
            window.open(googleMapsUrl, '_blank');
        }
    }
    return (
        <div style={{margin: '0px 25px'}}>
            {front_display_address ? (
                <Typography className='map-display-address' style={{textAlign: 'center', cursor: 'pointer' }}onClick={openMap}>
                    {front_display_address}
                </Typography>
            ) : (
                <p></p>
            )}
            
          {center ? (
            <div style={{ textAlign: 'center' }}>
              <LoadScript googleMapsApiKey={gmapsAPI}>
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={10}
                  options={mapOptions}
                >
                    <Marker 
                        position={center} 
                        onClick={() => setInfoWindowOpen(true)}
                    />
                    {infoWindowOpen && (
                    <InfoWindow 
                      position={center} 
                      onCloseClick={() => setInfoWindowOpen(false)}
                    >
                      <div style={{ display: 'flex', justifyContent: 'flex-start', gap: '5px', alignItems: 'center', marginBottom: '25px'}}>
                        <Typography variant="body1">{project_address}</Typography>
                        <OpenInNewIcon
                            variant="contained" 
                            color='#22527a'
                            onClick={openMap}
                        />
                      </div>
                    </InfoWindow>
                  )}

                </GoogleMap>
              </LoadScript>
              <Button 
                variant="contained" 
                className="smaller-primary-button"
                startIcon={<SatteliteAltIcon style={{fontSize: 'medium'}}/>}
                style={{
                    marginTop: '15px',
                    marginBottom: '-10px',
                }}
                onClick={openMap}
              >
                VIEW IN GOOGLE MAPS
              </Button>
            </div>
          ) : (
            <div style={{ textAlign: 'center' }}>
              <Typography variant="body1">--</Typography>
            </div>
          )}
        </div>
      );
    };

export default ProjectLocationMap;
