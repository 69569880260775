import React, { useState, useEffect } from "react";
import {
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  textField: {
    "& .MuiInputBase-input": {
      color: "white",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "white",
      },
      "&:hover fieldset": {
        borderColor: "white",
      },
      "&.Mui-focused fieldset": {
        borderColor: "white",
      },
    },
    "& .MuiInputLabel-root": {
      color: "white",
      "&.Mui-focused": {
        color: "white",
      },
    },
    "& .MuiAutocomplete-endAdornment .MuiSvgIcon-root": {
      color: "white",
    },
  },
  chip: {
    color: "white",
    backgroundColor: "transparent",
    border: "1px solid white",
    borderRadius: "5px",
    width: "auto",
  },
  chipDeleteIcon: {
    color: "white",
  },
}));

const DivisionSelect = ({ options, selectedValues, onChange, isLoading }) => {
  const classes = useStyles();
  return (
    <div className="select-dropdown-container-portfolio">
      <Autocomplete
        multiple
        value={selectedValues || []}
        onChange={(event, newValue) => {
          if (!isLoading && !newValue.includes("Loading...")) {
            onChange(newValue);
          }
        }}
        options={isLoading ? [] : options || []}
        getOptionLabel={(option) => option || ""}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Division"
            variant="outlined"
            fullWidth
            className={`${classes.textField} selector-font-control`}
          />
        )}
        noOptionsText={isLoading ? "Loading..." : "No options"} 
        isOptionEqualToValue={(option, value) => option === value}
        sx={{
          "& .MuiChip-root": {
            color: "white",
            backgroundColor: "transparent",
            border: "1px solid white",
            borderRadius: "5px",
            width: "auto",
          },
          "& .MuiChip-deleteIcon": {
            color: "white",
          },
        }}
      />
    </div>
  );
};

export default DivisionSelect;
