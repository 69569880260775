import React from "react";
import { CardContent, Typography } from "@material-ui/core";

const calculateMonthDifference = (startDateStr, endDateStr) => {
  if (startDateStr && endDateStr) {
    const startDate = new Date(startDateStr);
    const endDate = new Date(endDateStr);

    let months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();

    // Calculate the difference in days
    const startDay = startDate.getDate();
    const endDay = endDate.getDate();
    const daysInEndMonth = new Date(endDate.getFullYear(), endDate.getMonth() + 1, 0).getDate();
    const dayDifference = (endDay - startDay) / daysInEndMonth;

    // Adjusting for days
    months += dayDifference;

    // Return the result rounded to 1 decimal places
    return months <= 0 ? 0 : months.toFixed(1);
  }
  return null;
};
    
  const ProjectDurationCard = ({ projectData }) => {
    const substantialCompletion = projectData.project_data?.completion_date || projectData?.completion_date
    const startDate = projectData?.project_data?.actual_start_date || projectData?.actual_start_date
    const projectDuration = calculateMonthDifference(startDate, substantialCompletion);

    return (
        <div>
        {projectData && projectDuration ? (
            <Typography style={{ textAlign:'center'}} variant="body1" className='main-key-project-info-body'>
                {`${projectDuration} mos.` || '--' }
            </Typography>
        ) : (
            <Typography style={{ textAlign:'center'}} variant="body1" className='main-key-project-info-body'>--</Typography>
        )}
    </div>
    )
  }

  export default ProjectDurationCard;