import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Grid, Typography, IconButton, Button, Card, CardContent, FormControlLabel, Checkbox } from '@mui/material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Lottie from 'lottie-react';
import { getSortFields } from './continerUtilityFunctions';
import loadingAnimation from '../static/loadingAnimationLottie.json';
import PortfolioProjectTeamTable from '../Components/PortfolioViewComponents/ProjectTeamComponentForPortfolio';
import PortfolioMasterProjectInfoCard from '../Components/PortfolioViewComponents/MasterProjectInformation-PortfolioComponent';
import FinancialSummaryComponent from '../Components/PortfolioViewComponents/PortfolioCardFinancialSummaryComponent';


import { 
    setPageNumber, 
    setSelectedPortfolioCompany, 
    setSortField,
	setShowSortOptions,
	setPortfolioTeamData,
} from '../../../store/slices/portfolioSlice';



const useReduxSelectors = () => {
	const hubDomain = useSelector((state) => state.global.hubDomain);

	return { hubDomain};
};

const SORT_FIELDS = getSortFields();

const PortfolioDashboard = () => {
    const dispatch = useDispatch();
    const { hubDomain } = useReduxSelectors();
    const {
        portfolioProjects,
        portfolioError,
        sortField,
        pageNumber,
        totalResults,
        prevPageExists,
        nextPageExists,
        showSortOptions,
        portfolioTeamData,
    } = useSelector((state) => state.portfolio);

	const isLoading = useSelector((state) => state.global.isLoading);
	const selectedFieldLabel = SORT_FIELDS.find((sf) => sf.value === sortField)?.label || 'None';

	const handleToggleSortPanel = () => {
        dispatch(setShowSortOptions(!showSortOptions));
    };

    const handleSortChange = (e, value) => {
        dispatch(setSortField(value));
    };

    const handleViewProjectDetails = (project) => {
        window.open(`${hubDomain}/phd/?tab=project&project_id=${project.id}`, '_blank');
    };

    if (isLoading || !portfolioProjects || portfolioProjects.length === 0) {
        return (
            <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                <Grid container spacing={2} sx={{ marginTop: '15px', height: '90vh', width: '98vw', margin: '0 auto' }}>
                    <Grid item xs={12}>
                        <Typography variant="h6" align="center">
                            {isLoading ? 'Fetching projects...' : 'No projects found for the selected filters.'}
                        </Typography>
                        {isLoading && (
                            <Lottie
                                animationData={loadingAnimation}
                                loop
                                autoplay
                                style={{ width: 'auto', height: '50vh' }}
                            />
                        )}
                        {portfolioError && (
                            <Typography style={{ color: 'red', marginBottom: '1rem', textAlign: 'center' }}>
                                {portfolioError}
                            </Typography>
                        )}
                    </Grid>
                </Grid>
            </Box>
        );
    }

    return (
		<Grid container spacing={2} style={{ marginTop: '10px', width: '98vw', margin: '0 auto', borderRadius: '10px' }}>
		{/* PORTFOLIO SORTING CONTROL */}
		<Grid item xs={12}>
			<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
				<span style={{ display: 'flex', flexDirection: 'row', marginBottom: '5px', alignItems: "center" }}>
					<Typography style={{ color: 'black' }}>
						Sort Projects
					</Typography>
					<IconButton
						onClick={handleToggleSortPanel}
						size="small"
						sx={{ p: 0.5 }}
					>
						{showSortOptions ? <ExpandLessIcon /> : <ExpandMoreIcon />}
					</IconButton>
				</span>
				<Typography style={{
					 position: 'absolute', // Position absolutely to center it
					 left: '50%', // Move to the center horizontally
					 transform: 'translateX(-50%)', // Adjust for the element's width
					 marginBottom: '5px',
					 color: 'black',
					 fontSize: 15,
					 textAlign: 'center',
				}}>
					Showing {(pageNumber - 1) * 25 + 1}–{Math.min(pageNumber * 25, totalResults)} of {totalResults} projects
				</Typography>
				<Typography>Sorted By: {selectedFieldLabel}</Typography>
			</div>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					cursor: 'pointer',
					mb: 1,
				}}
			>
			</Box>

			{showSortOptions && (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						padding: 2,
						marginBottom: 2,
						backgroundColor: '#f5f5f5',
						borderRadius: '8px',
					}}
				>
					{SORT_FIELDS.map(({ label, value }) => (
						<FormControlLabel
							key={value}
							label={label}
							control={
								<Checkbox
									value={value}
									checked={sortField === value}
									onChange={(e) => handleSortChange(e, value)}
									icon={<CircleOutlinedIcon />}
									checkedIcon={<CheckCircleOutlineIcon />}
									sx={{
										'&.Mui-checked': {
											color: '#1976d2',
										},
									}}
								/>
							}
							sx={{
								marginLeft: 1,
								marginRight: 2,
								'.MuiFormControlLabel-label': {
									fontSize: '0.9rem',
								},
							}}
						/>
					))}
				</Box>
			)}
			<div style={{ display: 'flex', justifyContent: 'space-between', margin: '10px 0' }}>
				{/* Previous Button */}
				<Button
					className={prevPageExists ? 'pagination-button-enabled' : 'pagination-button-disabled'}
					onClick={() => {
						if (prevPageExists) {
							dispatch(setPageNumber(pageNumber - 1));
						}
					}}
					disabled={!prevPageExists}
					startIcon={<ChevronLeftIcon style={{ fontSize: 'small' }} />}
				>
					Prev
				</Button>

				{/* Next Button */}
				<Button
					className={nextPageExists ? 'pagination-button-enabled' : 'pagination-button-disabled'}
          data-after={true}
          onClick={() => {
						if (nextPageExists) {
							dispatch(setPageNumber(pageNumber + 1));
						}
					}}
					disabled={!nextPageExists}
					endIcon={<ChevronRightIcon style={{ fontSize: 'small' }} />}
				>
					Next
				</Button>
			</div>
		</Grid>
		<Grid container spacing={2}>

  {/* Map over all projects and assign to individual cards */}
  {portfolioProjects.map((project) => (
    <Grid item xs={12} key={project.id}>
      <Card className="project-card" style={{backgroundColor: '#093d61'}}>
        <CardContent className="project-card">
          {/* Card Header */}
          <div
            className="portfolio-card-header"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <Typography variant="h6">
              {project.project_number} | {project.name} | ARCO ID: {project.arco_id || 'N/A'}
            </Typography>
            <Button
              variant="text"
              style={{ color: 'white' }}
              size="small"
              onClick={() => handleViewProjectDetails(project)}
            >
              View Project Details
              <OpenInNewIcon style={{ marginLeft: '5px', fontSize: '20px' }} />
            </Button>
          </div>

          {/* Card Components */}
          <div className="portfolio-card-information-container">
            <Grid container spacing={2}>
              <Grid item xs={12} sm={3}>
                <CardContent className="portfolio-component-card" style={{ padding: '16px' }}>
                <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'flex-start',
                      height: '100%',
                    }}
                    >
                  <PortfolioProjectTeamTable
                    projectNumber={project.project_number}
                    arcoID={project.arco_id}
                    projectName={project.name}
                    roles={project.project_team || []}
                  />
                  </div>
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={4}>
                <CardContent className="portfolio-component-card" style={{ padding: '16px' }}>
                  <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    >
                  <PortfolioMasterProjectInfoCard projectData={project} />
                  </div>
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={3}>
                <CardContent className="portfolio-component-card" style={{ padding: '16px' }}>
                <div style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                    >
                  <FinancialSummaryComponent projectData={project} />
                  </div>
                </CardContent>
              </Grid>
              <Grid item xs={12} sm={2}>
                <CardContent className="portfolio-component-card" style={{ padding: '16px' }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      height: '100%',
                    }}
                  >
                    <Typography style={{ textAlign: 'center', fontSize: '.9rem' }}>
                      Billing Time Remaining <br></br>
                      (Coming Soon)
                    </Typography>
                  </div>
                </CardContent>
              </Grid>
            </Grid>
          </div>
        </CardContent>
      </Card>
    </Grid>
  ))}
</Grid>	

</Grid>)};
export default PortfolioDashboard;