import React from "react";
import { Typography } from "@material-ui/core";

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const RevisedBudget = ({ budgetData }) => {

    const getRevisedBudget = () => {
        if (budgetData && budgetData?.current_contract_summary?.revised_contract_value && budgetData?.current_contract_summary?.revised_contract_value !== undefined) {
            const revisedBudget = budgetData?.current_contract_summary?.revised_contract_value
            const roundedRevBudget = Math.ceil(revisedBudget)
            return `$${roundedRevBudget.toLocaleString()}`;
        }
        return '--';
    };

    return (
        <div>
            <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
                {getRevisedBudget()}
            </Typography>
        </div>
    );
}

export default RevisedBudget;