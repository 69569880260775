import React from "react";
import { useEffect } from "react";
import '../../static/percent-change-styles.css';

import { Card, CardContent, Typography, Box } from "@material-ui/core";

const formatDollar = (value) => {
    return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(value);
}

const CurrentContractAmount = ({ budgetData }) => {
    // Define contract values & calc difference
    const getCurrentContractValue = (budgetData) => {
        const original = budgetData?.current_contract_summary?.original_contract_value || 0;
        const approvedChanges = budgetData?.current_contract_summary?.approved_pcos_pccos || 0;
        const totalValue = original + approvedChanges;
        const roundedTotalValue = Math.ceil(totalValue);
        const currentContract = `$${roundedTotalValue.toLocaleString()}`;
        
        return currentContract;
    };

return (
    <div>
            {budgetData && budgetData?.current_contract_summary ? (
                <div style={{ textAlign: 'center' }}>
                    <Typography className="main-key-project-info-body">
                        {getCurrentContractValue(budgetData)}
                    </Typography>
                </div>
            ) : (
                    <Typography style={{textAlign: 'center'}}className="key-project-info-body">--</Typography>
            )}
        </div>
    );
}

export default CurrentContractAmount;