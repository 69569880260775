import React, { useEffect } from "react";

import { Card, CardContent, Typography } from "@material-ui/core";

const ProfitPercentage = ({ budgetData }) => {

    if (!budgetData || !budgetData.current_contract_summary || !budgetData.current_contract_summary.profit_percentage) {
        return (
            <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
                 --
             </Typography>
         ) 
    }
    
    const getProfitPercentage = () => {
        const latestProfitData = budgetData.current_contract_summary.profit_percentage;
        if (latestProfitData) {
            return `${latestProfitData.toFixed(2)}%`;
        }
        return (
           <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
                --
            </Typography>
        ) 
        
    };
return (
    <div>
    <Typography style={{ textAlign: 'center' }} variant="body1" className="main-key-project-info-body">
        {getProfitPercentage()}
    </Typography>
</div>
    );
}

export default ProfitPercentage;