import React from 'react';

import {Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper} from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { CardContent } from '@mui/material';

const useStyles = makeStyles({
	// table: {
	//     minWidth: 500,
	// }
});

const ConcurExpenseTracker = ({concurUrl, isModal}) => {
	const classes = useStyles();

	return (
		<CardContent >
			{concurUrl ? (
			<div style={{ width: '100%', height: '90vh' }}>
				<iframe
					src={concurUrl}
					style={{ width: '100%', height: '100%', border: 'none' }}
					title="Embedded Content"
					allowFullScreen
				/>
			</div>                        
			): (
				<Typography variant="h6" color="textSecondary" align="center">
					If you are having issues viewing the Concur Expense Tracker, try accessing the main Power BI website at <a href="https://app.powerbi.com/?pbi_source=websignin_uNav" target="_blank" rel="noreferrer">https://app.powerbi.com/?pbi_source=websignin_uNav</a>. Once you confirm your account, refresh the page and try again. If issues persit, please contact us at <a href="mailto:innovation@arcomurray.com?subject=PHD Feedback" target="_blank">innovation@arcomurray.com</a>.
				</Typography>
			)}
		</CardContent>
	);
};

export default ConcurExpenseTracker;
