import React from "react";
import { Card, CardContent, Typography } from "@material-ui/core";
import { formatDateShort } from "../utilityFunctions";

const WarrantyStartDate = ({ projectData }) => {
    const procoreWarrantyStartDate = projectData?.project_data?.warranty_start_date || projectData?.warranty_start_date;

return (
    <div>
        {projectData ? (
            <Typography style={{ textAlign:'center'}} className="main-key-project-info-body" variant="body1">
                {formatDateShort(procoreWarrantyStartDate) || '--'}
            </Typography>
        ) : (
            <Typography variant="body1">N/A - Select a Project</Typography>
        )}
    </div>
    );
}

export default WarrantyStartDate;